import React from "react";
import { Link as BaseLink } from "gatsby";

type Props = {
  className?: string;
  fontSize?: "md" | "sm" | "xs";
  text: string;
  to: string;
  special?: boolean;
};

export const Link: React.FunctionComponent<Props> = ({
  className = "",
  fontSize = "sm",
  text,
  to,
  special = false,
}) => (
  <BaseLink
    className={`font-poppins text-secondary hover:text-blue-600 text-${fontSize} ${className}`}
    style={{
      color: special ? "white" : "rgb(34, 69, 147)",
    }}
    to={to}
  >
    {text}
  </BaseLink>
);

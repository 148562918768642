import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Heading, Paragraph } from "~/components";
import PriceBlock from "~/components/PriceBlock";
import { prices } from "../ChardhamHome/prices";

export const DoDham: React.FunctionComponent = () => (
  <div className="">
    <StaticImage
      placeholder="blurred"
      className="h-[50vh]"
      src="../../images/badrinath-1.jpeg"
      alt="pilgrimage-banner"
      layout="fullWidth"
    />

    <section id="do-dham-package" className="mb-3">
      <Heading type="h1" className="mb-2">
        Do Dham by Helicopter
      </Heading>
      <div className="flex flex-col-reverse md:flex-row">
        <div className="flex-1 md:w-[50%]">
          <Heading type="title" className="mb-0 underline font-light">
            Duration
          </Heading>
          <Heading type="title" className="mt-0">
            1 Day(same day return)
          </Heading>
          <Heading type="title" className="mb-0 underline font-light">
            Route
          </Heading>
          <Heading type="title" className="mt-0 mb-0">
            Dehradun- Kedarnath- Badrinath- Dehradun
          </Heading>
        </div>
        <PriceBlock
          price={prices.doDham.price}
          discountedPrice={prices.doDham.discountedPrice}
          itineraryLink="https://d2wedyshsbbj5x.cloudfront.net/2024+Do+Dham+Yatra+T%26C+Do+Dham+v2.pdf"
          packageName="Do Dham Yatra package 1 Day"
          tncLink="https://d2wedyshsbbj5x.cloudfront.net/2024+Do+Dham+Yatra+T%26C+Do+Dham.pdf"
          priceDetails="Price per person (Early bird offer till 30th June)"
        />
      </div>
      <div className="flex-1 md:w-[50%] text-justify">
        <Heading type="title" className="mb-0 underline font-light">
          Package Includes
        </Heading>
        <ol className="text-md text-secondary mb-5 leading-relaxed font-poppins list-decimal ml-5">
          <li>
            Route: Dehradun - Phata- Kedarnath - Phata- Badrinath - Dehradun,
            same day.
          </li>
          <li>
            Dedicated ground staff assistance and priority darshan at Kedarnath
            and Badrinath.
          </li>
          <li>
            Shri Kedarnath ji Helicopter shuttle services: Phata Helipad-
            Kedarnath- Phata Helipad
          </li>
          <li>Local Transportation at all the Dhams.</li>
          <li>1 meal at Phata or Badrinath.</li>
          <li>Inclusive of all taxes, royalty and other charges.</li>
          <li>
            For more details on the yatra check out the itinerary, for more
            personalised options, keep reading and check out the add-ons
            section.
          </li>
        </ol>
      </div>
      <div className="flex-1 md:w-[50%] text-justify">
        <Heading type="title" className="mb-0 underline font-light">
          Package Exclusions
        </Heading>
        <Paragraph className="mb-0">
          Below are the package exclusions which can be arranged on demand at an
          additional charge:
        </Paragraph>
        <ol className="text-md text-secondary leading-relaxed font-poppins ml-5 list-decimal">
          <li>
            Pickup from Dehradun airport(or any location within city limits) and
            transit to the helipad.
          </li>
          <li>Accommodation at Dehradun with meal requests.</li>
          <li>
            Accommodation at Badrinath/Kedarnath, which would may include
            additional flying charges. (Better prices if informed prior)
          </li>
          <li>
            Hotels:
            <ul className="list-disc ml-5">
              <li>
                Kedarnath: Kedar Glow Resort/ Turiya Heli Resort/ Kedar Paradise
              </li>
              <li>Badrinath: Amritara The Avadh</li>
            </ul>
          </li>
          <li>
            Local sightseeing option with a Thumby Aviation guide only if
            accommodation option availed.
          </li>
          <li>
            Rudra Abhishek Puja at Kedarnath (INR 6,500 for 05 People approx.)
          </li>
          <li>
            Maha Abhishek Puja at Badrinath (Maha Abhishek Puja: INR
            4,300/Person (approx.))
          </li>
          <li>
            Triyuginarayan Temple at Kedarnath Dham(where Lord Shiva and Ma
            Parvati tied the knot)
          </li>
          <li>
            Note: Point 4-8 can only be availed only if accommodation is
            availed.
          </li>
          <li>Flights (Scheduled/Non-Scheduled) or Train Tickets Booking.</li>
          <li>Mussoorie, Haridwar or Rishikesh Day-trip.</li>
          <li>
            Pickup or/and drop from Mussoorie, Haridwar or Rishikesh to/from
            Dehradun.
          </li>
        </ol>
      </div>
    </section>
  </div>
);

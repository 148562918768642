import axios from "axios";
import { prod, sandbox, dev } from "./url";

// TODO: Do switch according to the env variable.
axios.defaults.baseURL =
  process.env.GATSBY_ENV === "prod"
    ? prod
    : process.env.GATSBY_ENV === "dev"
    ? sandbox
    : dev;

console.log(
  ">>>>>>>>>>>>>>>>>>>>>>>>>>>>>> process.env.GATSBY_ENV ",
  process.env.GATSBY_ENV
);
console.log(
  ">>>>>>>>>>>>>>>>>>>>>>>>>>>>>> axios.defaults.baseURL ",
  axios.defaults.baseURL
);
export * from "./charterSchedule";

import { StaticImage } from "gatsby-plugin-image";
import React from "react";

export const AlbumsLargeScreen: React.FunctionComponent = () => (
  <section className="mt-10 mb-28 hidden lg:block">
    <div className="flex">
      <StaticImage
        placeholder="blurred"
        className="rounded-md"
        src="../../images/heliwedding-01@2x.png"
        alt=""
        height={775}
      />

      <div>
        <div className="flex">
          <StaticImage
            placeholder="blurred"
            className="rounded-md ml-1"
            src="../../images/heliwedding-02@2x.png"
            alt=""
            height={255}
          />

          <StaticImage
            placeholder="blurred"
            className="rounded-md ml-1"
            src="../../images/heliwedding-03.png"
            alt=""
            height={255}
          />
        </div>

        <div className="flex mt-1">
          <div className="flex flex-col space-y-1">
            <StaticImage
              placeholder="blurred"
              className="rounded-md ml-1"
              src="../../images/heliwedding-04.png"
              alt=""
              height={255}
            />
            <StaticImage
              placeholder="blurred"
              className="rounded-md ml-1"
              src="../../images/heliwedding-05.png"
              alt=""
              height={255}
            />
          </div>

          <StaticImage
            placeholder="blurred"
            className="rounded-md ml-1"
            src="../../images/heliwedding-06.png"
            alt=""
            height={515}
          />
        </div>
      </div>
    </div>
    <div className="flex mt-1">
      <StaticImage
        placeholder="blurred"
        className="rounded-md ml-1"
        src="../../images/heliwedding-07.png"
        alt=""
        height={255}
      />

      <StaticImage
        placeholder="blurred"
        className="rounded-md ml-1"
        src="../../images/heliwedding-08.png"
        alt=""
        height={255}
      />
    </div>
    <div className="mt-1">
      <StaticImage
        placeholder="blurred"
        className="rounded-md ml-1"
        src="../../images/heliwedding-09@2x.png"
        alt=""
      />
    </div>
  </section>
);

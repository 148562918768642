import React from "react";

type Props = {
  className?: string;
  onClick: () => void;
};

export const HamburgerIcon: React.FunctionComponent<Props> = ({
  className = "",
  onClick,
}) => (
  <svg
    className={className}
    viewBox="0 0 100 80"
    width="20"
    height="20"
    fill="#194695"
    onClick={onClick}
  >
    <rect width="75" height="10"></rect>
    <rect y="30" width="75" height="10"></rect>
    <rect y="60" width="75" height="10"></rect>
  </svg>
);

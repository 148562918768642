import React from "react";
import { Accordion } from "react-bootstrap";
import { Heading } from "~/components";
import { prices } from "./prices";

const FAQ = () => {
  return (
    <div id="faq">
      <Heading type="h2" className="my-4 text-center">
        FAQs
      </Heading>
      <Accordion defaultActiveKey="0" aria-multiselectable>
        <Accordion.Item eventKey="0">
          <Accordion.Header className="font-bold">
            How long does the Chardham Yatra by Helicopter take?
          </Accordion.Header>
          <Accordion.Body>
            There are 2{" "}
            <a className="text-primary" href="/chardham-by-helicopter/packages">
              packages
            </a>{" "}
            to do the Chardham Yatra by Helicopter. The comprehensive and
            recommended package is of 5N 6D which ensures the best experience
            with ample time to savour the spiritual experience at each Dham and
            package inclusions of pickup/drop from Dehradun, helicopter yatra to
            all the Dhams, lavish accommodations, food, local sight-seeing
            options, road transport at each Dham, priority darshan and much more
            with a host of customizable add-on services. For a quick and time
            effective Yatra, there is an option of 1N 2D. Check out the detailed{" "}
            <a className="text-primary" href="/chardham-by-helicopter/packages">
              packages
            </a>
            .
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="1">
          <Accordion.Header>
            What is the price of the Chardham Yatra by Helicopter?
          </Accordion.Header>
          <Accordion.Body>
            5N 6D package: {prices.charDham5N6D.discountedPrice} / yatri (early
            bird offer to be revised to {prices.charDham5N6D.price}) <br /> 1N
            2D package: {prices.charDham1N2D.discountedPrice} for 6 yatris
            (early bird offer to be revised to {prices.charDham1N2D.price})
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="2">
          <Accordion.Header>
            How to book the Chardham Yatra by Helicopter?
          </Accordion.Header>
          <Accordion.Body>
            Click on the book now option, which will lead you to a prompt, where
            you can choose to message our customer care executive on Whatsapp,
            request a call back by adding your name and number or contact our
            sales team at 9400399999.
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="3">
          <Accordion.Header>
            Can I customise my Chardham Yatra by Helicopter itinerary?
          </Accordion.Header>
          <Accordion.Body>
            There are customizable elements in the{" "}
            <a
              className="text-primary"
              href="https://d2wedyshsbbj5x.cloudfront.net/2024+Char+Dham+Yatra+Itinerary+5N+6D.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              itinerary
            </a>{" "}
            which you can look into in the add-ons section and there are
            standardised elements such as order of helicopter route, etc..
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="4">
          <Accordion.Header>
            Are meals included in the Chardham Yatra helicopter package?
          </Accordion.Header>
          <Accordion.Body>
            Yes, all meals are included in the Chardham Yatra Helicopter
            package.
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="5">
          <Accordion.Header>
            What are the hotels where we will be provided accommodation?
          </Accordion.Header>
          <Accordion.Body>
            <ul className="text-sm mb-5 leading-relaxed list-decimal ml-5">
              <li>Dehradun: Fairfield by Marriott</li>
              <li>Yamunotri: Shiv Shakti Eco Resort</li>
              <li>Gangotri: Prakriti- The Retreat/ Awana Cottages </li>
              <li>Kedarnath: Kedar Glow Resort/ Turiya Heli Resort</li>
              <li>Badrinath: Amritara The Avadh</li>
            </ul>
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="6">
          <Accordion.Header>
            Can I visit the other nearby attractions during the Chardham Yatra
            by Helicopter?
          </Accordion.Header>
          <Accordion.Body>
            Yes, you will be aided by tourist guides from Thumby Aviation to
            visit nearby attractions subject to time and availability. You can
            read our{" "}
            <a className="text-primary" href="/blogs">
              blog
            </a>{" "}
            on the{" "}
            <a
              className="text-primary"
              href="/blogs/chardham-best-tourist-destinations"
            >
              best tourist destination
            </a>{" "}
            recommendations to visit in each of the Chardhams.
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="7">
          <Accordion.Header>
            What are the measures taken to ensure the safety of Yatris on the
            Helicopter Yatra?
          </Accordion.Header>
          <Accordion.Body>
            We are a DGCA approved heli-aviation operator with over a decade of
            incident/accident free flying. We adhere to all the DGCA guidelines
            with utmost emphasis on safety and security of our Yatris. We have a
            fleet of highly credentialed pilots with significant experiences in
            the Indian Airforce and Commercial Aviation sectors. Also, there
            will be comprehensive briefings to the Yatris before they board the
            helicopter.
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="8">
          <Accordion.Header>
            Are there adequate medical facilities available during the Yatra?
          </Accordion.Header>
          <Accordion.Body>
            Yes, there are medical facilities available in the Chardham Yatra.
            We advise you to go through the health advisory in our blog on the
            essential travel toolkit and if need be consult a doctor for
            recommendation on the same.
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="9">
          <Accordion.Header>
            Are there packages to Do Dham and Ek Dham by helicopter?
          </Accordion.Header>
          <Accordion.Body>
            Yes there are packages to both Do Dham and Ek Dham by helicopter.
            Checkout our{" "}
            <a className="text-primary" href="/chardham-by-helicopter/packages">
              packages
            </a>{" "}
            page for more information regarding{" "}
            <a
              className="text-primary"
              href="/chardham-by-helicopter/packages/do-dham"
            >
              Do Dham
            </a>{" "}
            and{" "}
            <a
              className="text-primary"
              href="/chardham-by-helicopter/packages/ek-dham"
            >
              Ek Dham
            </a>{" "}
            by helicopter.
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  );
};

export default FAQ;

import React from "react";
import {
  PrimaryButton,
  SecondaryButton,
} from "~/components/Button/PrimaryButton";
import { BookingRequest } from "~/views/BookingRequest";
import * as classes from "./styles.module.css";
import clsx from "clsx";

export default ({
  price,
  discountedPrice,
  tncLink,
  itineraryLink,
  packageName,
  priceDetails = "",
}: {
  price: string;
  discountedPrice: string;
  tncLink: string;
  itineraryLink: string;
  packageName: string;
  priceDetails?: string;
}) => {
  const [showBookingRequestForm, setShowBookingRequestForm] =
    React.useState(false);

  const onBook = () => {
    setShowBookingRequestForm(true);
  };

  const onClose = () => {
    setShowBookingRequestForm(false);
  };

  return (
    <>
      <div
        className={clsx(
          "w-[40%] flex gap-2 text-center px-2 mb-2",
          classes.priceBlock
        )}
      >
        <div className="flex flex-col">
          <div className={classes.priceHeader}>
            <div className={classes.earlyBirdTag}>Early bird offer</div>
            <div className={clsx("flex justify-center items-center")}>
              <div
                className={clsx(
                  "mr-4 line-through text-lg text-[red]",
                  classes.actualPrice
                )}
              >
                {price}
              </div>
              <div className={clsx("font-bold text-2xl")}>
                {discountedPrice}
              </div>
            </div>
          </div>
          <div className={classes.priceDetails}>
            <div className="text-l">{priceDetails}</div>
          </div>
        </div>
        <div>
          {tncLink && (
            <div className="mb-2">
              <a
                className="underline hover:text-blue-500"
                href={tncLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                Terms & Conditions
              </a>
            </div>
          )}
          <div className={clsx("gap-2 flex-1 mb-2", classes.buttonsWrapper)}>
            {itineraryLink && (
              <SecondaryButton
                className="hover:bg-[#18469580] hover:text-white"
                onClick={() => {
                  window.open(itineraryLink, "_blank", "noopener,noreferrer");
                }}
              >
                Download Itinerary
              </SecondaryButton>
            )}

            <div className={classes.booknow}>
              <PrimaryButton
                className={clsx("hover:opacity-[80%] hover:text-white")}
                onClick={onBook}
              >
                Book Now
              </PrimaryButton>
            </div>
          </div>
        </div>
      </div>

      {showBookingRequestForm && (
        <BookingRequest onClose={onClose} enquiryType={packageName} />
      )}
    </>
  );
};

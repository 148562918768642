import { StaticImage } from "gatsby-plugin-image";
import React from "react";

export const AlbumsMobileScreen: React.FunctionComponent = () => (
  <section className="mt-10 mb-20 flex md:hidden flex-col items-center">
    {/* row - 01 */}
    <div className="flex">
      <StaticImage
        placeholder="blurred"
        className="rounded-md"
        src="../../images/heliwedding-01@2x.png"
        alt=""
        height={325}
      />

      <div className="flex flex-col space-y-1">
        <StaticImage
          placeholder="blurred"
          className="rounded-md ml-1"
          src="../../images/heliwedding-02.png"
          alt=""
          height={105}
        />
        <StaticImage
          placeholder="blurred"
          className="rounded-md ml-1"
          src="../../images/heliwedding-04.png"
          alt=""
          height={215}
        />
      </div>
    </div>

    {/* row - 02 */}
    <div className="mt-1">
      <StaticImage
        placeholder="blurred"
        className="rounded-md"
        src="../../images/heliwedding-02@2x.png"
        alt=""
        height={158}
      />
    </div>

    {/* row - 03 */}
    <div className="mt-1 flex">
      <StaticImage
        placeholder="blurred"
        className="rounded-md w-[200px]"
        src="../../images/heliwedding-07.png"
        alt=""
        height={107}
      />
      <StaticImage
        placeholder="blurred"
        className="rounded-md w-[125px] ml-1"
        src="../../images/heliwedding-03.png"
        alt=""
        height={107}
      />
    </div>

    {/* row - 04 */}
    <div className="mt-1">
      <StaticImage
        placeholder="blurred"
        className="rounded-md"
        src="../../images/heliwedding-06@2x.png"
        alt=""
        height={312}
      />
    </div>

    {/* row - 05 */}
    <div className="mt-1">
      <StaticImage
        placeholder="blurred"
        className="rounded-md"
        src="../../images/heliwedding-09.png"
        alt=""
        height={158}
      />
    </div>
  </section>
);

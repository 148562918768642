import React from "react";
import { StaticImage } from "gatsby-plugin-image";

import { Heading, Paragraph } from "~/components";

export const AboutUsParagraph: React.FunctionComponent = () => (
  <div className={``}>
    <div className="flex flex-col clg:flex-row flex-wrap clg:space-x-10 items-start clg:items-center">
      <StaticImage
        placeholder="blurred"
        className="max-w-[200px] clg:max-w-full"
        src="../../../images/about_us-creative@2x.png"
        alt=""
        height={400}
      />

      <div className="flex-1 clg:mr-10 max-w-[600px]">
        <Heading type="h2" className="text-header-dark">
          About Us
        </Heading>
        <Paragraph className="leading-loose">
          Led by{" "}
          <strong className="font-semibold">Gp. Capt. Dr. KNG Nair.</strong>, an
          Indian Air Force Veteran with a rich experience in Aviation, Thumby
          Aviation has been an operator in General Aviation for almost a decade.
          Our Clientele include State Government's, Central Government Agencies,
          Political Parties, Corporates, HNI's, Celebrities and other Private
          clients.
        </Paragraph>

        <Paragraph className="font-semibold leading-loose">
          We, at Thumby Aviation, are passionate about the Aviation Industry and
          strive to be front runners for the changing aviation scene in the
          Indian Subcontinent.
        </Paragraph>
      </div>
    </div>
  </div>
);

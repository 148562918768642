import React from "react";
import { Heading, Paragraph, PrimaryButton } from "~/components";

import IllustrationHelitaxi from "~/images/illustration-helitaxi.svg";
import { BookingRequest } from "../BookingRequest";

export const ShuttlesAndCharters: React.FunctionComponent = () => {
  const [showBookingRequestForm, setShowBookingRequestForm] =
    React.useState(false);

  return (
    <div className="mb-10 clg:mb-20">
      <Heading className="block clg:hidden">
        HeliTaxii Shuttles & Charters
      </Heading>

      <div className="flex flex-col-reverse clg:flex-row clg:justify-between">
        <div className="clg:w-[46%]">
          <Heading className="hidden clg:block">
            HeliTaxii Shuttles & Charters
          </Heading>

          <section>
            <Heading type="h3">HeliTaxii Shuttles</Heading>
            <Paragraph>
              Pay by the seat with our HeliTaxii shuttles. We operate intra-city
              and airport transfers in Bangalore city. HeliTaxii shuttles to
              popular tourist destinations like Coorg, Kabini, Chikmagaluru,
              Hampi etc. from Bangalore to commence soon!
            </Paragraph>

            <PrimaryButton
              onClick={() => {
                setShowBookingRequestForm(true);
              }}
            >
              Book Now
            </PrimaryButton>
          </section>

          <section>
            <Heading type="h3">HeliTaxii Charter</Heading>

            <Paragraph>
              Hop on a helicopter and travel for business and leisure in style
              with HeliTaxii Charters. Time is money, and with HeliTaxii
              Charters, we maximize your productive time by minimizing your
              travel time. Ease of booking, luxury of time, choice of helipads
              closest to your destination and your comfortable armchair in the
              sky makes HeliTaxii Charters the smartest choice!
            </Paragraph>

            <Paragraph>
              <strong className="font-poppins font-semibold">
                Popular Routes for Helicopter travel for Business
              </strong>{" "}
              (HeliTaxii Business): Bangalore, Hubli, Belgaum, Chennai, Kochi,
              Mysore, Shimoga, Mangalore, Coimbatore, Trivandrum, Hyderabad ,
              Vishakapatanam, Vijayawada, Vellore / Warangal etc.
            </Paragraph>
          </section>

          <section>
            <Heading type="h3">HeliTaxii Leisure</Heading>

            <Paragraph>
              Popular Destinations in South India: Coorg , Chikmagaluru , Kabini
              , Hampi , Wayanad , Kumarakom , Ooty/Conoor , Kodaikanal ,
              Pondicherry, Goa , Munnar etc.
            </Paragraph>
          </section>
        </div>

        <div className="w-80 h-76 mt-5">
          <img src={IllustrationHelitaxi} alt="locations" />
        </div>
      </div>
      {showBookingRequestForm && (
        <BookingRequest
          enquiryType="General"
          onClose={() => {
            setShowBookingRequestForm(false);
          }}
        />
      )}
    </div>
  );
};

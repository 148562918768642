import React from "react";

import { Heading } from "~/components";
import * as classes from "./styles.module.css";

export const LeftSection: React.FunctionComponent = () => (
  <div className={classes.leftSectionWrapper}>
    <Heading className="mt-0 !text-h2 text-center">
      Your Partner In The Sky
    </Heading>
  </div>
);

export const defaultLinks: {
  to?: string;
  text: string;
  special?: boolean;
  links?: { to: string; text: string }[];
  type: "link" | "menu";
}[] = [
  {
    to: "/chardham-by-helicopter",
    text: "Chardham by helicopter",
    type: "link",
    special: true,
  },
  {
    to: "/about-us",
    text: "About us",
    type: "link",
  },
  {
    to: "/#operating-locations",
    text: "Popular Destinations",
    type: "link",
  },
  {
    to: "",
    text: "Services",
    links: [
      {
        to: "/chardham-by-helicopter",
        text: "Chardham by helicopter",
      },
      {
        to: "/helitaxii-shuttles-and-charters",
        text: "HeliTaxii Shuttles & Charters",
      },
      {
        to: "/heli-vacations-and-tourisms",
        text: "Heli-Vacations & tourisms",
      },
      {
        to: "/heli-weddings",
        text: "Heli-Weddings",
      },
      {
        to: "/government-works",
        text: "Government Works",
      },
      {
        to: "/thumby-charters",
        text: "Thumby Charters",
      },
      {
        to: "/heli-celebrations-other-services",
        text: "Heli-Celebrations & Other Services",
      },
    ],
    type: "menu",
  },
  {
    to: "/blogs",
    text: "Blogs",
    type: "link",
  },
];

export const chardhamLinks: {
  to?: string;
  text: string;
  links?: { to: string; text: string }[];
  type: "link" | "menu";
  special?: boolean;
}[] = [
  {
    to: "/",
    text: "Home",
    type: "link",
  },
  {
    to: "/chardham-by-helicopter",
    text: "Chardham by helicopter",
    type: "link",
    special: true,
  },
  {
    to: "",
    text: "Packages",
    type: "menu",
    links: [
      {
        to: "/chardham-by-helicopter",
        text: "Chardham by Helicopter 5N/6D",
      },
      {
        to: "/chardham-by-helicopter/packages/do-dham",
        text: "Do Dham by Helicopter",
      },
      {
        to: "/chardham-by-helicopter/packages/ek-dham",
        text: "Ek Dham by Helicopter",
      },
      {
        to: "/chardham-by-helicopter/packages/chardham-1n-2d",
        text: "Chardham by Helicopter 1N/2D",
      },
    ],
  },
  {
    to: "/chardham-by-helicopter/#testimonials",
    text: "Testimonials",
    type: "link",
  },
  {
    to: "/blogs",
    text: "Blogs",
    type: "link",
  },
  {
    to: "/chardham-by-helicopter/#photo-gallery",
    text: "Photo gallery",
    type: "link",
  },
  {
    to: "/chardham-by-helicopter/#our-clients",
    text: "Our clients",
    type: "link",
  },
  {
    to: "/chardham-by-helicopter/#faq",
    text: "FAQs",
    type: "link",
  },
];

import React from "react";

type Props = {
  text: string;
  className?: string;
};

export const Chip: React.FunctionComponent<Props> = ({
  className = "",
  text,
}) => (
  <span
    className={`bg-[#03d5cc33] py-1 px-4 rounded-3xl my-1 mr-1 ${className}`}
  >
    {text}
  </span>
);

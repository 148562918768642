import clsx from "clsx";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import { Heading } from "~/components";
import * as classes from "./styles.module.css";

export const Blogs: React.FunctionComponent = () => (
  <div id="blogs" className={`mt-2`}>
    <Heading className="text-center" type="h2">
      Blogs
    </Heading>
    <div
      style={{
        overflowX: "scroll",
      }}
    >
      <div className={clsx("flex gap-4", classes.scrollableContenWrapper)}>
        <Link to="/blogs/history-of-chardham-unleash-chardham-by-helicopter">
          <div
            className={clsx(
              "bg-white rounded overflow-hidden shadow-lg h-[100%]",
              classes.slide
            )}
          >
            <StaticImage
              src="../../images/blog-1-cover-image.png"
              alt="icon-testimonial"
              placeholder="blurred"
              className="h-[250px] min-w-[350px]"
              layout="fullWidth"
            />
            <div
              className="px-4 pb-2"
              style={{
                borderTop: "1px solid #ccc",
              }}
            >
              <Heading type="h3" className="!text-md mb-0 mt-2 text-center">
                History of Chardham:
                <br /> Unleash Chardham Yatra by Helicopter
              </Heading>
            </div>
          </div>
        </Link>

        <Link to="/blogs/chardham-best-tourist-destinations">
          <div
            className={clsx(
              "bg-white rounded overflow-hidden shadow-lg h-[100%]",
              classes.slide
            )}
          >
            <StaticImage
              src="../../images/chardham-new.jpeg"
              alt="icon-testimonial"
              placeholder="blurred"
              className="h-[250px] min-w-[350px]"
              layout="fullWidth"
            />
            <div
              className="px-4 pb-2"
              style={{
                borderTop: "1px solid #ccc",
              }}
            >
              <Heading type="h3" className="!text-md mb-0 mt-2 text-center">
                Chardham by Helicopter: <br />
                Best Tourist Destinations
              </Heading>
            </div>
          </div>
        </Link>

        <Link to="/blogs/travel-toolkit-chardham">
          <div
            className={clsx(
              "bg-white rounded overflow-hidden shadow-lg h-[100%]",
              classes.slide
            )}
          >
            <StaticImage
              src="../../images/headgear.png"
              alt="icon-testimonial"
              placeholder="blurred"
              className="h-[250px] min-w-[350px]"
              layout="fullWidth"
            />
            <div
              className="px-4 pb-2"
              style={{
                borderTop: "1px solid #ccc",
              }}
            >
              <Heading type="h3" className="!text-md mb-0 mt-2 text-center">
                Chardham Yatra by Helicopter:
                <br /> A Comprehensive Traveler's Toolkit
              </Heading>
            </div>
          </div>
        </Link>

        <Link to="/blogs/benefits-of-helicopter-travel">
          <div
            className={clsx(
              "bg-white rounded overflow-hidden shadow-lg h-[100%]",
              classes.slide
            )}
          >
            <StaticImage
              src="../../images/bg/1-chardham-carousel.png"
              alt="icon-testimonial"
              placeholder="blurred"
              className="h-[250px] min-w-[350px]"
              layout="fullWidth"
            />
            <div
              className="px-4 pb-2"
              style={{
                borderTop: "1px solid #ccc",
              }}
            >
              <Heading type="h3" className="!text-md mb-0 mt-2 text-center">
                Chardham by Helicopter:
                <br /> Benefits of Helicopter Travel
              </Heading>
            </div>
          </div>
        </Link>

        <Link to="/blogs/guide-to-planning-your-yatra">
          <div
            className={clsx(
              "bg-white rounded overflow-hidden shadow-lg h-[100%]",
              classes.slide
            )}
          >
            <StaticImage
              src="../../images/blog-guide.png"
              alt="icon-testimonial"
              placeholder="blurred"
              className="h-[250px] min-w-[350px]"
              layout="fullWidth"
            />
            <div
              className="px-4 pb-2"
              style={{
                borderTop: "1px solid #ccc",
              }}
            >
              <Heading type="h3" className="!text-md mb-0 mt-2 text-center">
                Chardham by Helicopter:
                <br /> Guide to Planning your Yatra
              </Heading>
            </div>
          </div>
        </Link>

        <Link to="/blogs/chardham-best-service-provider">
          <div
            className={clsx(
              "bg-white rounded overflow-hidden shadow-lg h-[100%]",
              classes.slide
            )}
          >
            <StaticImage
              src="../../images/blog-cover-6.jpg"
              alt="icon-testimonial"
              placeholder="blurred"
              className="h-[250px] min-w-[350px]"
              layout="fullWidth"
            />
            <div
              className="px-4 pb-2"
              style={{
                borderTop: "1px solid #ccc",
              }}
            >
              <Heading type="h3" className="!text-md mb-0 mt-2 text-center">
                Chardham by Helicopter
                <br />
                Best Service Provider
              </Heading>
            </div>
          </div>
        </Link>

        <Link to="/blogs/chardham-yatris-experience">
          <div
            className={clsx(
              "bg-white rounded overflow-hidden shadow-lg h-[100%]",
              classes.slide
            )}
          >
            <StaticImage
              src="../../images/bg/2-carousal-1.png"
              alt="icon-testimonial"
              placeholder="blurred"
              className="h-[250px] min-w-[350px]"
              layout="fullWidth"
            />
            <div
              className="px-4 pb-2"
              style={{
                borderTop: "1px solid #ccc",
              }}
            >
              <Heading type="h3" className="!text-md mb-0 mt-2 text-center">
                Chardham by Helicopter
                <br />
                Yatris' Experience
              </Heading>
            </div>
          </div>
        </Link>
      </div>
    </div>
  </div>
);

import React from "react";

import { SalientFeatures } from "./SalientFeatures";

export const AboutUs: React.FunctionComponent = () => (
  <div
    id="about-us"
    className="mt-5 py-5"
    style={{
      borderTop: "1px solid #e5e5e5",
    }}
  >
    {/* Row 1 Image + text */}
    <SalientFeatures />
  </div>
);

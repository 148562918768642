import React from "react";
import { StaticImage } from "gatsby-plugin-image";

export const Logo: React.FunctionComponent = () => {
  return (
    <StaticImage
      placeholder="blurred"
      src="../../../images/logo-thumby@2x.png"
      alt="logo"
      height={70}
    />
  );
};

export const MobileLogo: React.FunctionComponent = () => {
  return (
    <StaticImage
      placeholder="blurred"
      src="../../../images/logo-thumby@2x.png"
      alt="logo"
      height={30}
      objectFit="contain"
    />
  );
};

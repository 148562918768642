import React from "react";

type Props = {
  className?: string;
};

export const Close: React.FunctionComponent<Props> = ({ className = "" }) => (
  <svg
    className={className}
    focusable="false"
    viewBox="0 0 24 24"
    aria-hidden="true"
    fill="currentColor"
  >
    <path d="M19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path>
  </svg>
);

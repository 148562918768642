import React from "react";
import { Paragraph } from "~/components";
import { Logo } from "~/components/Layout/Logo";

export const LogoAndMission = () => (
  <>
    <Logo />
    <Paragraph>
      We are an aviation company into the business of buying/Leasing helicopters
      and fixed wing aircrafts for non scheduled air transport operations
    </Paragraph>
  </>
);

import React from "react";

type Props = {
  children?: React.ReactNode;
  className?: string;
  font?: "poppins" | "montserrat";
  style?: React.CSSProperties;
  _dangerouslySetInnerHTML?: string;
};

export const Paragraph: React.FunctionComponent<Props> = ({
  font = "poppins",
  children,
  className = "",
  style = {},
  _dangerouslySetInnerHTML = "",
}) =>
  _dangerouslySetInnerHTML ? (
    <p
      className={`text-md text-secondary mb-5 leading-relaxed ${
        font === "poppins" ? "font-poppins" : "font-montserrat"
      } ${className}`}
      style={style}
      dangerouslySetInnerHTML={{
        __html: _dangerouslySetInnerHTML,
      }}
    />
  ) : (
    <p
      className={`text-md text-secondary mb-5 leading-relaxed ${
        font === "poppins" ? "font-poppins" : "font-montserrat"
      } ${className}`}
      style={style}
    >
      {children}
    </p>
  );

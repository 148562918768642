import React from "react";

import { Heading, Slider } from "~/components";

import HelicopterSvg from "~/images/about_us-Icon 01.svg";
import JoyStickSvg from "~/images/about_us-Icon 02.svg";
import HandShakeSvg from "~/images/about_us-Icon 03.svg";
import CapSvg from "~/images/about_us-Icon 04.svg";
import LocationWithFlySvg from "~/images/about_us-Icon 05.svg";
import SafeHandsSvg from "~/images/Chatered-icon 01.svg";
import LocationPathSvg from "~/images/Chatered-icon 03.svg";

type CardProps = {
  text: string;
  imagePath: string;
  header: string;
};

const features: CardProps[] = [
  {
    header: "Legacy",
    text: "40+ years in Aviation & 20+ years in Aviation consultancy",
    imagePath: HelicopterSvg,
  },
  {
    header: "Reliability",
    text: "Capabilities to fly In difficult & restricted terrains",
    imagePath: JoyStickSvg,
  },
  {
    header: "Safety",
    text: "Over a decade of Accident/Incident Free flying",
    imagePath: SafeHandsSvg,
  },
  {
    header: "Competency",
    text: "Rich experience in working with Government Bodies",
    imagePath: HandShakeSvg,
  },
  {
    header: "Workforce",
    text: "A stellar roaster of experienced & passionate pilots & engineers",
    imagePath: CapSvg,
  },
  {
    header: "Convinience",
    text: "We prepare landing pads where you wish to fly to",
    imagePath: LocationPathSvg,
  },
];

const Card: React.FunctionComponent<CardProps> = ({
  text,
  imagePath,
  header,
}) => (
  <div className="w-[220px] min-h-[250px] text-header-dark font-md bg-white rounded-xl inline-block px-4 pt-4 mr-5 mb-5">
    <div className="">
      <img className="w-16" src={imagePath} alt="" />
    </div>
    <p className="font-montserrat font-medium text-m leading-normal pt-4">
      {header}
    </p>
    <p className="font-montserrat font-light text-s leading-normal pt-2">
      {text}
    </p>
  </div>
);

export const SalientFeatures: React.FunctionComponent = () => {
  return (
    <div>
      <Heading type="h3" className="mt-16 text-2xl text-center">
        Where We Excel?
      </Heading>

      <div className="mb-10">
        <Slider
          settings={{
            variableWidth: true,
            responsive: [
              {
                breakpoint: 1024,
                settings: {
                  slidesToShow: 3,
                  slidesToScroll: 3,
                },
              },
              {
                breakpoint: 600,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 2,
                },
              },
              {
                breakpoint: 480,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                  infinite: true,
                },
              },
            ],
          }}
        >
          {features.map(({ text, imagePath, header }) => (
            <Card
              key={imagePath}
              text={text}
              imagePath={imagePath}
              header={header}
            />
          ))}
        </Slider>
      </div>
    </div>
  );
};

import React from "react";
import { Link } from "~/components/Layout/Link";

export const CompanyLinks = () => (
  <div className="flex flex-col space-y-4">
    <span className="text-xl font-medium">COMPANY</span>
    <Link
      className="font-thin"
      fontSize="md"
      to="/about-us"
      text="About company"
    />
    <Link
      className="font-thin"
      fontSize="md"
      to="/#operating-locations"
      text="Operations"
    />
    <Link className="font-thin" fontSize="md" to="/#services" text="Services" />
    <Link
      className="font-thin"
      fontSize="md"
      to="/#future-projects"
      text="Future"
    />
  </div>
);

import React from "react";

export const LegalLinks = () => (
  <div className="flex flex-col space-y-4">
    <span className="text-xl font-medium">LEGALS</span>
    <a
      className="font-thin text-sm font-poppins text-secondary hover:text-blue-600"
      href="https://helitaxii.com/privacy-policy"
    >
      Privacy Policy
    </a>
    <a
      className="font-thin text-sm font-poppins text-secondary hover:text-blue-600"
      href="https://helitaxii.com/terms-and-conditions"
    >
      Terms
    </a>
  </div>
);

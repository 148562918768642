import React from "react";
import { StaticImage } from "gatsby-plugin-image";

export type OperatingLocation = {
  place: string;
  state: string;
  Image: React.FunctionComponent;
};

export const operatingLocations: OperatingLocation[] = [
  {
    place: "Kedarnath",
    state: "Uttarakhand",
    Image: () => (
      <div className="bg-locations inline-block rounded-2xl">
        <StaticImage
          className="-z-10 w-[200px] h-[300px] clg:w-[240px] clg:h-[400px] rounded-2xl"
          src="../../../images/do-dham.png"
          alt=""
          placeholder="blurred"
          loading="eager"
        />
      </div>
    ),
  },
  {
    place: "Badrinath",
    state: "Uttarakhand",
    Image: () => (
      <div className="bg-locations inline-block rounded-2xl">
        <StaticImage
          className="-z-10 w-[200px] h-[300px] clg:w-[240px] clg:h-[400px] rounded-2xl"
          src="../../../images/badrinath-1.jpeg"
          alt=""
          placeholder="blurred"
          loading="eager"
        />
      </div>
    ),
  },
  {
    place: "Amarnath",
    state: "Jammu & Kashmir",
    Image: () => (
      <div className="bg-locations inline-block rounded-2xl">
        <StaticImage
          className="-z-10 w-[200px] h-[300px] clg:w-[240px] clg:h-[400px] rounded-2xl"
          src="../../../images/amarnath.png"
          alt=""
          placeholder="blurred"
          loading="eager"
        />
      </div>
    ),
  },
  {
    place: "Manimahesh",
    state: "Himachal Pradesh",
    Image: () => (
      <div className="bg-locations inline-block rounded-2xl">
        <StaticImage
          className="-z-10 w-[200px] h-[300px] clg:w-[240px] clg:h-[400px] rounded-2xl"
          src="../../../images/manimahesh.png"
          alt=""
          placeholder="blurred"
          loading="eager"
        />
      </div>
    ),
  },
  {
    place: "Kabini",
    state: "Kerala",
    Image: () => (
      <div className="bg-locations inline-block rounded-2xl">
        <StaticImage
          className="-z-10 w-[200px] h-[300px] clg:w-[240px] clg:h-[400px] rounded-2xl"
          src="../../../images/image-location 02@2x.png"
          alt=""
          placeholder="blurred"
          loading="eager"
        />
      </div>
    ),
  },
  {
    place: "Chikmagaluru",
    state: "Karnataka",
    Image: () => (
      <div className="bg-locations inline-block rounded-2xl">
        <StaticImage
          className="-z-10 w-[200px] h-[300px] clg:w-[240px] clg:h-[400px] rounded-2xl"
          src="../../../images/image-location 03@2x.png"
          alt=""
          placeholder="blurred"
          loading="eager"
        />
      </div>
    ),
  },
  {
    place: "Wayanad",
    state: "Kerala",
    Image: () => (
      <div className="bg-locations inline-block rounded-2xl">
        <StaticImage
          className="-z-10 w-[200px] h-[300px] clg:w-[240px] clg:h-[400px] rounded-2xl"
          src="../../../images/image-location 04@2x.png"
          alt=""
          placeholder="blurred"
          loading="eager"
        />
      </div>
    ),
  },
  {
    place: "Kumarakom",
    state: "Karnataka",
    Image: () => (
      <div className="bg-locations inline-block rounded-2xl">
        <StaticImage
          className="-z-10 w-[200px] h-[300px] clg:w-[240px] clg:h-[400px] rounded-2xl"
          src="../../../images/image-location 05@2x.png"
          alt=""
          placeholder="blurred"
          loading="eager"
        />
      </div>
    ),
  },
  {
    place: "Goa",
    state: "Goa",
    Image: () => (
      <div className="bg-locations inline-block rounded-2xl">
        <StaticImage
          className="-z-10 w-[200px] h-[300px] clg:w-[240px] clg:h-[400px] rounded-2xl"
          src="../../../images/goa.jpeg"
          alt=""
          placeholder="blurred"
          loading="eager"
        />
      </div>
    ),
  },
  {
    place: "Pondicherry",
    state: "Pondicherry",
    Image: () => (
      <div className="bg-locations inline-block rounded-2xl">
        <StaticImage
          className="-z-10 w-[200px] h-[300px] clg:w-[240px] clg:h-[400px] rounded-2xl"
          src="../../../images/pondicherry.jpeg"
          alt=""
          placeholder="blurred"
          loading="eager"
        />
      </div>
    ),
  },
  {
    place: "Hampi",
    state: "Hampi",
    Image: () => (
      <div className="bg-locations inline-block rounded-2xl">
        <StaticImage
          className="-z-10 w-[200px] h-[300px] clg:w-[240px] clg:h-[400px] rounded-2xl"
          src="../../../images/hampi.jpeg"
          alt=""
          placeholder="blurred"
          loading="eager"
        />
      </div>
    ),
  },
  {
    place: "Munnar",
    state: "Munnar",
    Image: () => (
      <div className="bg-locations inline-block rounded-2xl">
        <StaticImage
          className="-z-10 w-[200px] h-[300px] clg:w-[240px] clg:h-[400px] rounded-2xl"
          src="../../../images/munnar.jpeg"
          alt=""
          placeholder="blurred"
          loading="eager"
        />
      </div>
    ),
  },
];

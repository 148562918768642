import React from "react";
import { StaticImage } from "gatsby-plugin-image";

import { Heading, Slider } from "~/components";
import * as classes from "./style.module.css";

export const TrustedClients: React.FunctionComponent = () => (
  <div id="trusted-clients" className={`mt-10 mb-12`}>
    <Heading className="text-center mb-2" type="h2">
      Out Trusted Clients
    </Heading>

    <div className={classes.imageWrapper}>
      <StaticImage
        src="../../../images/government-nagaland.png"
        alt=""
        placeholder="blurred"
        height={80}
        style={{ alignSelf: "self-end" }}
      />
      <StaticImage
        src="../../../images/government-of-telangana.png"
        alt=""
        placeholder="blurred"
        height={70}
      />
      <StaticImage
        src="../../../images/karnataka-tourism.png"
        alt=""
        placeholder="blurred"
        height={60}
      />
      <StaticImage
        src="../../../images/goverment-andra-pradesh.png"
        alt=""
        placeholder="blurred"
        height={80}
      />
      <StaticImage
        src="../../../images/ucada.png"
        alt=""
        placeholder="blurred"
        height={50}
      />
      <StaticImage
        src="../../../images/tally.png"
        alt=""
        placeholder="blurred"
        height={40}
      />
      <StaticImage
        src="../../../images/curefit.svg"
        alt=""
        placeholder="blurred"
        height={50}
      />
      <StaticImage
        src="../../../images/bsf.png"
        alt=""
        placeholder="blurred"
        height={50}
      />
      <StaticImage
        src="../../../images/wipro.png"
        alt=""
        placeholder="blurred"
        height={50}
      />
      <StaticImage
        src="../../../images/attica.png"
        alt=""
        placeholder="blurred"
        height={50}
      />
      <StaticImage
        src="../../../images/bjp.png"
        alt=""
        placeholder="blurred"
        height={50}
      />
      <StaticImage
        src="../../../images/titan.png"
        alt=""
        placeholder="blurred"
        height={30}
      />
      <StaticImage
        src="../../../images/caratlane.png"
        alt=""
        placeholder="blurred"
        height={50}
      />
      <StaticImage
        src="../../../images/tvs.png"
        alt=""
        placeholder="blurred"
        height={40}
      />
      <StaticImage
        src="../../../images/greenko.png"
        alt=""
        placeholder="blurred"
        height={50}
      />
      <StaticImage
        src="../../../images/nagaland-state.png"
        alt=""
        placeholder="blurred"
        height={90}
      />
      <StaticImage
        src="../../../images/gawar.webp"
        alt=""
        placeholder="blurred"
        height={80}
      />
      <StaticImage
        src="../../../images/arvee.png"
        alt=""
        placeholder="blurred"
        height={80}
      />
    </div>

    <div className={classes.mobileImageWrapper}>
      <Slider
        settings={{
          slidesToScroll: 2,
          slidesToShow: 3,
        }}
      >
        <div className="px-2 text-center">
          <StaticImage
            src="../../../images/government-nagaland.png"
            alt=""
            placeholder="blurred"
            objectFit="contain"
            className="h-[100px]"
          />
        </div>
        <div className="px-2 text-center">
          <StaticImage
            src="../../../images/government-of-telangana.png"
            alt=""
            placeholder="blurred"
            className="h-[100px] px-3"
            objectFit="contain"
          />
        </div>
        <div className="px-2 text-center">
          <StaticImage
            src="../../../images/karnataka-tourism.png"
            alt=""
            placeholder="blurred"
            className="h-[100px] px-2 text-center"
            objectFit="contain"
          />
        </div>
        <div className="px-2 text-center">
          <StaticImage
            src="../../../images/goverment-andra-pradesh.png"
            alt=""
            placeholder="blurred"
            className="h-[100px] px-2 text-center"
            objectFit="contain"
          />
        </div>
        <div className="px-2 text-center">
          <StaticImage
            src="../../../images/ucada.png"
            alt=""
            placeholder="blurred"
            className="h-[100px]"
            objectFit="contain"
          />
        </div>
        <div className="px-2 text-center">
          <StaticImage
            src="../../../images/tally.png"
            alt=""
            placeholder="blurred"
            className="h-[100px]"
            objectFit="contain"
          />
        </div>
        <div className="px-2 text-center">
          <StaticImage
            src="../../../images/curefit.svg"
            alt=""
            placeholder="blurred"
            className="h-[100px]"
            objectFit="contain"
          />
        </div>
        <div className="px-2 text-center">
          <StaticImage
            src="../../../images/bsf.png"
            alt=""
            placeholder="blurred"
            className="h-[100px]"
            objectFit="contain"
          />
        </div>
        <div className="px-2 text-center">
          <StaticImage
            src="../../../images/wipro.png"
            alt=""
            placeholder="blurred"
            className="h-[100px]"
            objectFit="contain"
          />
        </div>
        <div className="px-2 text-center">
          <StaticImage
            src="../../../images/attica.png"
            alt=""
            placeholder="blurred"
            className="h-[100px]"
            objectFit="contain"
          />
        </div>
        <div className="px-2 text-center">
          <StaticImage
            src="../../../images/bjp.png"
            alt=""
            placeholder="blurred"
            className="h-[100px]"
            objectFit="contain"
          />
        </div>
        <div className="px-2 text-center">
          <StaticImage
            src="../../../images/titan.png"
            alt=""
            placeholder="blurred"
            className="h-[100px]"
            objectFit="contain"
          />
        </div>
        <div className="px-2 text-center">
          <StaticImage
            src="../../../images/caratlane.png"
            alt=""
            placeholder="blurred"
            className="h-[100px]"
            objectFit="contain"
          />
        </div>
        <div className="px-2 text-center">
          <StaticImage
            src="../../../images/tvs.png"
            alt=""
            placeholder="blurred"
            className="h-[100px]"
            objectFit="contain"
          />
        </div>
        <div className="px-2 text-center">
          <StaticImage
            src="../../../images/greenko.png"
            alt=""
            placeholder="blurred"
            className="h-[100px]"
            objectFit="contain"
          />
        </div>
        <div className="px-2 text-center">
          <StaticImage
            src="../../../images/nagaland-state.png"
            alt=""
            placeholder="blurred"
            className="h-[100px]"
            objectFit="contain"
          />
        </div>
        <div className="px-2 text-center">
          <StaticImage
            src="../../../images/gawar.webp"
            alt=""
            placeholder="blurred"
            className="h-[100px]"
            objectFit="contain"
          />
        </div>
        <div className="px-2 text-center">
          <StaticImage
            src="../../../images/arvee.png"
            alt=""
            placeholder="blurred"
            className="h-[100px]"
            objectFit="contain"
          />
        </div>
      </Slider>
    </div>
  </div>
);

import React, { useState } from "react";
import { Link } from "gatsby";
import * as classes from "./styles.module.css";
import { HamburgerIcon } from "~/components/Icons";
import { Navbar } from "~/components/Layout/Navbar";
import { Logo } from "~/components/Layout/Logo";
import clsx from "clsx";
import { MobileLogo } from "../Logo/Logo";

type Props = {
  className?: string;
  links: {
    to?: string;
    text: string;
    links?: { to: string; text: string }[];
    type: "link" | "menu";
  }[];
  showBanner?: boolean;
};

export const Header: React.FunctionComponent<Props> = ({
  className = "",
  links,
  showBanner = false,
}) => {
  const [showNavBar, setShowNavBar] = useState(false);

  const onToggleMobileMenuVisibility = () => {
    setShowNavBar(!showNavBar);
  };

  return (
    <header className={className}>
      {/* Static Banner */}
      {showBanner && (
        <a href="/chardham-by-helicopter/">
          <div className={classes.fixedHeader}>
            Chardham and Do Dham Charters Sep 15<sup>th</sup> - Oct 20
            <sup>th</sup> 2024
          </div>
        </a>
      )}
      <div className={classes.mobileMenuButton}>
        <HamburgerIcon onClick={onToggleMobileMenuVisibility} />
        <Link to="/">
          <MobileLogo />
        </Link>
      </div>
      <div
        className={clsx(classes.mobileMenuOverlay, {
          "!block": showNavBar,
        })}
        onClick={onToggleMobileMenuVisibility}
      />
      <div
        className={clsx(classes.navigationContainer, {
          "!left-[0]": showNavBar,
        })}
      >
        <div className={classes.logoWrapper}>
          <Link to="/">
            <Logo />
          </Link>
        </div>
        <div className={clsx(classes.navbarWrapper)}>
          <Navbar
            toggleMobileMenuVisibility={onToggleMobileMenuVisibility}
            links={links}
          />
        </div>
      </div>
    </header>
  );
};

import React from "react";
import { StaticImage } from "gatsby-plugin-image";

export type Service = {
  title: string;
  text: string;
  Image: React.FunctionComponent;
  link: string;
};

export const servicesOfferred: Service[] = [
  {
    title: "HeliTaxii Shuttles and Charters",
    text: "HeliTaxii Shuttles & Charters offers convenient intra-city and airport transfers in Bangalore, as well as luxurious helicopter travel to popular destinations and cities across South India for both business and leisure.",
    link: "/helitaxii-shuttles-and-charters",
    Image: () => (
      <div className="bg-services inline-block">
        <StaticImage
          className="-z-10 hover:border-light-400"
          src="../../../images/Image-Servcie 01@2x.png"
          alt=""
          placeholder="blurred"
          loading="eager"
          height={500}
        />
      </div>
    ),
  },
  {
    title: "HeliTaxii Pilgrimage",
    text: "HeliTaxii shuttle services from Phata to Shri Kedarnath Ji, Chardhams, customized pilgrim tours in South India and other.",
    link: "/helitaxii-for-pilgrimage",
    Image: () => (
      <div className="bg-services inline-block">
        <StaticImage
          className="-z-10"
          src="../../../images/Image-Servcie 02@2x.png"
          alt=""
          placeholder="blurred"
          loading="eager"
          height={500}
        />
      </div>
    ),
  },
  {
    title: "Heli-Vacations & Heli-Tourism",
    text: "Spend time Vacationing and no time on the road. Book a Heli-Vacation and teleport to your holiday destination!!",
    link: "/heli-vacations-and-tourisms",
    Image: () => (
      <div className="bg-services inline-block">
        <StaticImage
          className="-z-10"
          src="../../../images/Image-Servcie 03@2x.png"
          alt=""
          placeholder="blurred"
          loading="eager"
          height={500}
        />
      </div>
    ),
  },
  {
    title: "Heli-Weddings",
    text: "Fly in to your wedding, tie the knot and fly out to your honeymoon! Click to know more about specially curated Heli-Weddings!",
    link: "/heli-weddings",
    Image: () => (
      <div className="bg-services inline-block">
        <StaticImage
          className="-z-10"
          src="../../../images/Image-Servcie 04@2x.png"
          alt=""
          placeholder="blurred"
          loading="eager"
          height={500}
        />
      </div>
    ),
  },
];

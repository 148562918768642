import React from "react";

import { Heading, Paragraph, PrimaryButton } from "~/components";

import { AlbumsMobileScreen } from "./AlbumsMobileScreen";
import { AlbumsMediumScreen } from "./AlbumsMediumScreen";
import { AlbumsLargeScreen } from "./AlbumsLargeScreen";
import { BookingRequest } from "../BookingRequest";

export const HeliWeddings: React.FunctionComponent = () => {
  const [showBookingRequestForm, setShowBookingRequestForm] =
    React.useState(false);

  return (
    <div>
      <Heading>Heli-Weddings</Heading>
      <Paragraph className="clg:w-[55%]">
        Ever dreamt of a personal helicopter to fly in for your wedding?
        Heli-Wedding with Thumby is the perfect option. Fly into your wedding
        and fly in your close and loved ones. A helicopter is parked standby
        through your wedding. Photoshoots, flower shower during the ceremony and
        Joy Rides for guests are all part of the package. Make your wedding
        truly special and memorable!
      </Paragraph>
      <div className="my-10">
        <PrimaryButton
          onClick={() => {
            setShowBookingRequestForm(true);
          }}
        >
          Book Now
        </PrimaryButton>
      </div>
      <AlbumsMobileScreen />
      <AlbumsMediumScreen />
      <AlbumsLargeScreen />
      {showBookingRequestForm && (
        <BookingRequest
          enquiryType="General"
          onClose={() => {
            setShowBookingRequestForm(false);
          }}
        />
      )}
    </div>
  );
};

import React, { useMemo, useState } from "react";
import { TBookingRequest, createBooking } from "~/api";

import { Close, Heading, Modal, Paragraph, PrimaryButton } from "~/components";

import Loader from "./Loader";
import { BookingRequestForm } from "./BookingRequestForm";
import { StaticImage } from "gatsby-plugin-image";

export const BookingRequest: React.FunctionComponent<{
  onClose: () => void;
}> = ({ onClose }) => {
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [bookingId, setBookingId] = useState<string>("");
  const campaign = useMemo(() => {
    const utmSource = localStorage.getItem("utmSource") || "";
    const utmMedium = localStorage.getItem("utmMedium") || "organic";
    const utmCampaign = localStorage.getItem("utmCampaign") || "organic";

    if (utmSource) {
      return {
        utmSource,
        utmMedium,
        utmCampaign,
      };
    }

    return {
      utmSource: "organic",
      utmMedium: "organic",
      utmCampaign: "organic",
    };
  }, []);

  const handleSubmit = async (scheduleId: string, data: TBookingRequest) => {
    setIsLoading(true);
    try {
      setBookingId("");
      const id = await createBooking(scheduleId, {
        ...data,
        campaign,
      });

      setBookingId(id);

      if (
        campaign.utmSource.toLowerCase().trim() === "count" &&
        (window as any).TrackierWebSDK
      ) {
        (window as any).TrackierWebSDK.trackConv(
          "count.gotrackier.com",
          "661788e7628a76393945f6f8",
          { is_iframe: true }
        );
      }
      setShowSuccessMessage(true);
    } catch (error: any) {
    } finally {
      setIsLoading(false);
      localStorage.removeItem("utmSource");
      localStorage.removeItem("utmMedium");
      localStorage.removeItem("utmCampaign");
    }
  };

  const closeModal = () => {
    setShowSuccessMessage(false);
    onClose();
  };

  if (showSuccessMessage) {
    return (
      <Modal onClose={closeModal}>
        <div className="flex flex-col min-h-screen justify-center items-center py-5">
          <div className="flex flex-col px-5 clg:flex-row rounded-lg mt-10 relative">
            <button
              className="block clg:hidden w-8 my-3 self-end float-right absolute top-1 right-7 text-white z-10"
              onClick={closeModal}
            >
              <Close className="h-7 w-7" />
            </button>{" "}
            <div className="w-full clg:w-[450px] bg-white rounded-lg">
              <div className="flex justify-center align-center pt-10">
                <StaticImage
                  placeholder="blurred"
                  src="../../images/popup-image.png"
                  alt="Request Sent"
                />
              </div>
              <div className="flex justify-center align-center flex-col px-10">
                <Heading className="text-center" type="h2">
                  Heli on the way!
                </Heading>
                <Paragraph className="text-primary text-center">
                  Our trip planner is mapping your Helitaxii route. And they
                  shall revert to you shortly.
                </Paragraph>
                {bookingId && (
                  <Paragraph className="text-primary text-center">
                    Your Booking ID is <strong>{bookingId}</strong>
                  </Paragraph>
                )}
              </div>
              <div className="text-center pb-10">
                <PrimaryButton onClick={closeModal}>Close</PrimaryButton>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }

  return (
    <Modal onClose={onClose}>
      <div className="flex flex-col min-h-screen justify-center items-center py-5">
        <div
          className="flex flex-col px-5 clg:flex-row rounded-lg mt-10 relative w-full clg:w-[800px]"
          onClick={(e) => e.stopPropagation()}
        >
          {isLoading && (
            <div className="absolute flex items-center justify-center z-[1001] h-full w-full bg-slate-400/50">
              <Loader />
            </div>
          )}
          <BookingRequestForm
            onSubmit={handleSubmit}
            onClose={onClose}
            setLoading={setIsLoading}
          />
        </div>
      </div>
    </Modal>
  );
};

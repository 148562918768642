import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import { Heading, Paragraph, Slider } from "~/components";
import * as classes from "./styles.module.css";
import clsx from "clsx";

const Testimonials = () => {
  return (
    <section>
      <div className="text-center" id="testimonials">
        <div className={`py-5 relative`}>
          <Heading type="h2" className="">
            Here’s what our amazing clients are saying
          </Heading>

          <div
            className={clsx(
              "bg-white mr-5 flex gap-4 align-center",
              classes.mainTestimonialWrapper
            )}
          >
            <div style={{ background: "white" }}>
              <div className="rounded-full">
                <StaticImage
                  className="rounded-full"
                  src="../../images/sara-ali-khan.png"
                  alt="icon-testimonial"
                  placeholder="blurred"
                  height={150}
                  width={150}
                  layout="fixed"
                />
              </div>

              <div className="flex-1 text-primary font-montserrat">
                <div className={classes.reviewAuthorName}>Sara Ali Khan</div>
                <div className={classes.reviewAuthorDescription}>
                  Indian Actress
                </div>
              </div>
            </div>
            <div className={classes.mainReviewContent}>
              <Paragraph
                font="montserrat"
                className={classes.reviewContent}
                style={{ color: "white" }}
              >
                The amount of detailing that went into the designing of my
                experience and the customization of services on my request in
                the form of add-on services was something that made me cherish
                the Yatra all the way long. The most interesting thing that sets
                them apart is that the service quality is consistently
                excellent, yet so diverse everytime I travel with them.
              </Paragraph>
            </div>
          </div>
          <div className="my-5">
            <Slider
              settings={{
                slidesToScroll: 1,
                slidesToShow: 3,
                dots: false,
                responsive: [
                  {
                    breakpoint: 1024,
                    settings: {
                      slidesToShow: 1,
                      slidesToScroll: 1,
                      infinite: true,
                      dots: true,
                    },
                  },
                ],
              }}
            >
              <div className="w-[300px] clg:w-[440px] bg-white rounded-2xl p-5 mr-5 ">
                <div className="flex flex-col items-start">
                  <StaticImage
                    src="../../images/icon-testimonial.svg"
                    alt="icon-testimonial"
                    placeholder="blurred"
                    objectFit="contain"
                  />

                  <Paragraph
                    font="montserrat"
                    className={clsx(classes.reviewContent, "mb-[40px]")}
                  >
                    The overall experience flying with Thumby Aviation was
                    great. What stood out for me was the professionalism
                    displayed by their ground staff, excellent service quality,
                    and dedication to exceed customer expectations.
                  </Paragraph>
                </div>

                <div className="flex items-center">
                  <div className="rounded-full">
                    <StaticImage
                      className="rounded-full"
                      src="../../images/shanthi-guruswamy.png"
                      alt="icon-testimonial"
                      placeholder="blurred"
                      height={75}
                      width={75}
                    />
                  </div>

                  <div className="flex-1 ml-5 text-primary font-montserrat">
                    <div className={classes.reviewAuthorName}>
                      Shanthi Guruswamy
                    </div>
                    <div className={classes.reviewAuthorDescription}>
                      Joint General Manager(Air Traffic Control)
                    </div>
                  </div>
                </div>
              </div>

              {/* <div className="w-[300px] clg:w-[440px] bg-white rounded-2xl p-5 mr-5">
                <div className="flex flex-col items-start">
                  <StaticImage
                    src="../../images/icon-testimonial.svg"
                    alt="icon-testimonial"
                    placeholder="blurred"
                    objectFit="contain"
                  />

                  <Paragraph
                    font="montserrat"
                    className={classes.reviewContent}
                  >
                    I have worked with Thumby for over 5 years now for Aviation
                    requirements for the State of Telangana and Andhra Pradesh.
                    They have a team of well qualified and experienced pilots &
                    engineers who have been undertaking our government flying
                    tasks with utmost safety.
                  </Paragraph>
                </div>

                <div className="flex items-center">
                  <div className="rounded-full">
                    <StaticImage
                      className="rounded-full"
                      src="../../images/Bharat_Review_01.jpg"
                      alt="icon-testimonial"
                      placeholder="blurred"
                      height={75}
                      width={75}
                    />
                  </div>

                  <div className="flex-1 ml-5 text-primary font-montserrat">
                    <div className={classes.reviewAuthorName}>
                      Mr. Bharat Reddy
                    </div>
                    <div className={classes.reviewAuthorDescription}>
                      Director Aviation, Telangana State Aviation Corp. Ltd. &
                      Advisor, Andhra Pradesh Aviation
                    </div>
                  </div>
                </div>
              </div> */}
            </Slider>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Testimonials;

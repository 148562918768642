import React from "react";

type Props = {
  onClick?: () => void;
  children?: React.ReactNode;
  className?: string;
  type?: "button" | "submit" | "reset" | undefined;
};

export const PrimaryButton: React.FunctionComponent<Props> = ({
  onClick,
  children,
  className = "",
  type = "button",
}) => (
  <button
    className={`bg-primary-dark hover:bg-secondary text-white text-md font-montserrat py-2 px-5 rounded-md ${className}`}
    onClick={onClick}
    type={type}
    name={children?.toString()}
    aria-label={children?.toString()}
  >
    {children}
  </button>
);

export const SecondaryButton: React.FunctionComponent<Props> = ({
  onClick,
  children,
  className = "",
  type = "button",
}) => (
  <button
    className={`bg-secondary-dark hover:bg-secondary-light text-black border border-primary-dark text-md font-montserrat py-2 px-5 rounded-md ${className}`}
    onClick={onClick}
    type={type}
    name={children?.toString()}
    aria-label={children?.toString()}
  >
    {children}
  </button>
);

import React from "react";
import { StaticImage } from "gatsby-plugin-image";

import { Heading } from "~/components";

import { Text } from "./Text";

export const CelebrationsOtherServices: React.FunctionComponent = () => (
  <div className="mt-10 mb-20">
    <Heading>
      Heli-Celebrations & <br /> Other Services
    </Heading>

    <section className="mt-10 space-y-16 clg:space-y-8 relative">
      <div className="absolute -top-[65px] right-[35px] hidden clg:block">
        <StaticImage
          placeholder="blurred"
          src="../../images/dot grid.svg"
          alt=""
          // height={70}
        />
      </div>

      <div className="clg:flex clg:space-x-10">
        <StaticImage
          placeholder="blurred"
          className="rounded-lg h-[140px] w-[210px]"
          src="../../images/pexels-matheus-bertelli-2868216.png"
          alt=""
        />
        <Text>
          Photo Shoot / Video Shoot / <br /> Film Shooting with Helicopter
        </Text>
      </div>

      <div className="clg:flex clg:space-x-10">
        <StaticImage
          placeholder="blurred"
          className="rounded-lg h-[140px] w-[210px]"
          src="../../images/pexels-marián-šicko-4243188.png"
          alt=""
        />
        <Text>
          Flower Petal Shower (Flower <br /> Dropping) with helicopter
        </Text>
      </div>

      <div className="clg:flex clg:space-x-10">
        <StaticImage
          placeholder="blurred"
          className="rounded-lg h-[140px] w-[210px]"
          src="../../images/pexels-david-bartus-2854416.png"
          alt=""
          height={140}
        />
        <Text>
          Aerial Surveys / <br /> Reconnaissance
        </Text>
      </div>

      <div className="clg:flex clg:space-x-10">
        <StaticImage
          placeholder="blurred"
          className="rounded-lg h-[140px] w-[210px]"
          src="../../images/medical-services.png"
          alt=""
          height={140}
        />
        <Text>
          Medical Services <br /> (Organ Transportation , Medical <br />{" "}
          Evacuation etc)
        </Text>
      </div>
    </section>
  </div>
);

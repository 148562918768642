import React from "react";
import { Chips, Heading, Paragraph } from "~/components";

const popularDestinations = [
  "Coorg",
  "Chikmagaluru",
  "Kabini",
  "Hampi",
  "Wayanad",
  "Kumarakom",
  "Ooty",
  "Conoor",
  "Kodaikanal",
  "Pondicherry",
  "Goa",
  "Munnar",
  "etc..",
];

export const HeliVacations: React.FunctionComponent = () => (
  <section>
    <Heading type="h3">Heli-Vacations</Heading>
    <Paragraph>
      Specially curated Heli-Vacation packages which lets you spend maximum time
      on your vacation and almost no time in your travel! Bid adieu to the long
      winding and rickety roads and fly in style to your vacation.
    </Paragraph>

    <Heading type="h3">Popular Destinations</Heading>
    <Chips items={popularDestinations} />
  </section>
);

import { StaticImage } from "gatsby-plugin-image";
import React from "react";

export const TitleRow: React.FunctionComponent = () => (
  <div className="flex flex-col-reverse xl:flex-row justify-between items-center">
    <span
      className="block text-[46px] clg:text-[81px] clg:max-w-[660px] font-montserrat 
    leading-[1.1] font-semibold text-white"
    >
      Something we are Working on for the{" "}
      <span className="text-[#0ad5cc]">FUTURE</span>
    </span>
    <StaticImage
      src="../../../images/illustration-future.svg"
      alt="illustration-future"
      height={275}
      placeholder="blurred"
    />
  </div>
);

import { StaticImage } from "gatsby-plugin-image";
import React from "react";

export const AlbumsMediumScreen: React.FunctionComponent = () => (
  <section className="mt-10 mb-20 hidden md:block lg:hidden">
    medium
    <div className="flex">
      <StaticImage
        placeholder="blurred"
        className="rounded-md"
        src="../../images/heliwedding-01@2x.png"
        alt=""
        height={400}
      />

      <div className="flex flex-col space-y-1">
        <StaticImage
          placeholder="blurred"
          className="rounded-md ml-1"
          src="../../images/heliwedding-02.png"
          alt=""
          height={130}
        />
        <StaticImage
          placeholder="blurred"
          className="rounded-md ml-1"
          src="../../images/heliwedding-04.png"
          alt=""
          height={230}
        />
      </div>
      <div className="space-y-1">
        <StaticImage
          placeholder="blurred"
          className="rounded-md ml-1"
          src="../../images/heliwedding-02@2x.png"
          alt=""
          height={143}
        />
        <StaticImage
          placeholder="blurred"
          className="rounded-md ml-1"
          src="../../images/heliwedding-09.png"
          alt=""
          height={143}
        />
      </div>
    </div>
    <div className="mt-1 flex">
      <StaticImage
        placeholder="blurred"
        className="rounded-md h-[200px] w-[390px]"
        src="../../images/heliwedding-07.png"
        alt=""
        height={107}
      />
      <StaticImage
        placeholder="blurred"
        className="rounded-md w-[200px] ml-1"
        src="../../images/heliwedding-03.png"
        alt=""
        height={107}
      />
    </div>
    <div className="mt-1">
      <StaticImage
        placeholder="blurred"
        className="rounded-md w-[600px] h-[360px]"
        src="../../images/heliwedding-06@2x.png"
        alt=""
      />
    </div>
  </section>
);

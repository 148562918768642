import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import { Heading, Paragraph } from "~/components";

type Project = {
  id: string;
  Image: React.FunctionComponent;
  Title: React.FunctionComponent;
  description: string;
};

const projects: Project[] = [
  {
    id: "heli-taxii",
    Title: () => (
      <Heading type="h3" className="text-[#FFF] mb-1">
        Heli <span className="text-[#ffd421]">Taxi</span>
      </Heading>
    ),
    description:
      "Introduction of cost effective vertical take-off and landing next generation Sky Cars to widen network of intra-city helitaxiis",
    Image: () => (
      <StaticImage
        src="../../../images/icon-future 01.svg"
        alt="future-01"
        placeholder="blurred"
        loading="eager"
        height={35}
      />
    ),
  },
  {
    id: "air-ambulance",
    Title: () => (
      <Heading type="h3" className="text-[#FFF] mb-1">
        Air <span className="text-[#ff4848]">Ambulance</span>
      </Heading>
    ),
    description:
      "Heli-Ambulances to take patients / accident victims to the nearest hospitals within the golden hour and save lives.",
    Image: () => (
      <StaticImage
        src="../../../images/icon-future 02.svg"
        alt="future-01"
        placeholder="blurred"
        loading="eager"
        height={35}
      />
    ),
  },
  {
    id: "connecting-rural-india",
    Title: () => (
      <Heading type="h3" className="text-[#FFF] mb-1">
        Connecting <span className="text-[#30b120]">Rural</span> India
      </Heading>
    ),
    description:
      "Introduction of cost effective vertical take-off and landing next generation Sky Cars to widen network of intra-city helitaxiis",
    Image: () => (
      <StaticImage
        src="../../../images/icon-future 03.svg"
        alt="future-01"
        placeholder="blurred"
        loading="eager"
        height={35}
      />
    ),
  },
];

type CardProps = Omit<Project, "id">;

const Card: React.FunctionComponent<CardProps> = ({
  Image,
  Title,
  description,
}) => (
  <div className="flex flex-col items-start text-white mt-5 clg:mt-0 mr-10">
    <Image />
    <div className="mt-2 clg:mt-4">
      <Title />
      <Paragraph className="text-[#FFF] text-[12px]">{description}</Paragraph>
    </div>
  </div>
);

export const Projects: React.FunctionComponent = () => (
  <div className="flex flex-col clg:flex-row mt-10 clg:mt-20">
    {projects.map(({ id, Title: title, description, Image }) => (
      <Card key={id} Image={Image} Title={title} description={description} />
    ))}
  </div>
);

import clsx from "clsx";
import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import { Heading } from "~/components";
import * as classes from "./styles.module.css";
import { prices } from "./prices";

const PriceSection = ({
  price,
  discountedPrice,
}: {
  price: string;
  discountedPrice: string;
}) => {
  return (
    <div className="flex flex-col">
      <div className={classes.earlyBirdBadge}>Early bird offer</div>
      <div className="flex items-center">
        <div
          className="ml-auto mr-[5px]"
          style={{ textDecoration: "line-through", color: "red" }}
        >
          {price}
        </div>
        <div className="text-xl font-bold">{discountedPrice}</div>
      </div>
    </div>
  );
};

export const PackageDetails = ({
  name,
  price,
  discountedPrice,
  duration,
  route,
  priceDetails = "",
}: {
  name: string;
  price: string;
  discountedPrice: string;
  duration: string;
  route: string;
  priceDetails?: string;
}) => {
  return (
    <div className="px-2 py-2 flex flex-col flex-grow">
      <div className="font-semibold text-xl mb-2">{name}</div>
      <PriceSection price={price} discountedPrice={discountedPrice} />
      {priceDetails && <div className="ml-auto">{priceDetails}</div>}
      <div className="">
        <div className="underline font-medium">Duration</div>{" "}
        <div>{duration}</div>
      </div>
      <div>
        <div className="underline font-medium">Route</div>{" "}
        <div className="text-sm">{route}</div>
      </div>
      <div
        className="mt-auto text-right"
        style={{
          color: "#e2b779",
        }}
      >
        Priority Darshan & Much more
      </div>
    </div>
  );
};

const OtherPackages = () => {
  return (
    <section>
      <Heading type="h2" className="text-center mt-16">
        Other packages
      </Heading>
      <div
        style={{
          overflowX: "scroll",
        }}
      >
        <div className={clsx("flex gap-4", classes.scrollableContenWrapper)}>
          <a href="/chardham-by-helicopter/packages/do-dham">
            <div
              className={clsx(
                "w-[350px] h-[100%] rounded overflow-hidden shadow-lg bg-white flex flex-col hover:shadow-none transition duration-300 border border-gray-200",
                classes.slide
              )}
            >
              <StaticImage
                src="../../images/do-dham.png"
                alt="Do Dham"
                placeholder="blurred"
                className="h-[250px] min-w-[250px]"
                layout="fullWidth"
              />
              <PackageDetails
                name="Do Dham 1D Package"
                price={prices.doDham.price}
                discountedPrice={prices.doDham.discountedPrice}
                duration="1 Day (Same day return)"
                route="Dehradun - Kedarnath - Badrinath - Dehradun"
                priceDetails="Price per yatri"
              />
            </div>
          </a>
          <a href="/chardham-by-helicopter/packages/chardham-1n-2d">
            <div
              className={clsx(
                "w-[350px] h-[100%] rounded overflow-hidden shadow-lg bg-white flex flex-col hover:shadow-none transition duration-300 border border-gray-200",
                classes.slide
              )}
            >
              <StaticImage
                src="../../images/chardham-new.jpeg"
                alt="Chardham"
                placeholder="blurred"
                className="h-[250px] min-w-[250px]"
                layout="fullWidth"
              />
              <PackageDetails
                name="Chardham 1N/2D Package"
                price={prices.charDham1N2D.price}
                discountedPrice={prices.charDham1N2D.discountedPrice}
                duration="1 Night 2 Days"
                route="Dehradun - Yamunotri - Gangotri - Kedarnath - Badrinath"
                priceDetails="Price per charter 6 yatri"
              />
            </div>
          </a>

          <a href="/chardham-by-helicopter/packages/ek-dham">
            <div
              className={clsx(
                "w-[350px] h-[100%] rounded overflow-hidden shadow-lg bg-white flex flex-col hover:shadow-none transition duration-300 border border-gray-200",
                classes.slide
              )}
            >
              <StaticImage
                src="../../images/ekdham-new.jpeg"
                alt="Kedarnath"
                placeholder="blurred"
                className="h-[250px] min-w-[250px]"
                layout="fullWidth"
              />
              <PackageDetails
                name="Ek Dham 1D Package"
                price={prices.ekDham.price}
                discountedPrice={prices.ekDham.discountedPrice}
                duration="1 Day (Same day return)"
                route="Dehradun - Kedarnath - Dehradun"
                priceDetails="Price per charter 6 yatris"
              />
            </div>
          </a>
        </div>
      </div>
    </section>
  );
};

export default OtherPackages;

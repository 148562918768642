import React, { useEffect } from "react";
import useOnClickOutside from "use-onclickoutside";
import { Link } from "~/components/Layout/Link";

type MenuItemProps = {
  onClick: () => void;
  show: boolean;
  name: string;
};
const MenuItem: React.FunctionComponent<MenuItemProps> = ({
  onClick,
  show,
  name,
}) => (
  <button
    className="inline-flex items-center font-poppins text-secondary text-sm hover:text-blue-600 cursor-pointer focus:outline-none p-2"
    onClick={onClick}
  >
    <span>{name}</span>
    <svg
      width="6"
      height="3"
      className="ml-2 overflow-visible"
      aria-hidden="true"
      style={{
        transform: show ? "rotate(180deg)" : "rotate(0deg)",
        transition: "transform 0.3s ease",
      }}
    >
      <path
        d="M0 0L3 3L6 0"
        fill="none"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  </button>
);

type Props = {
  show: boolean;
  links: { to: string; text: string }[];
};

const SubMenu: React.FunctionComponent<Props> = ({ show, links }) => (
  <div
    className={`${show ? "block" : "clg:hidden"} top-8 clg:bg-white
      flex flex-col rounded`}
  >
    {links.map(({ to, text }) => (
      <Link to={to} text={text} className="p-2 block ml-3" />
    ))}
  </div>
);

export const LinkMenu = ({
  config,
}: {
  config: { name: string; links: { to: string; text: string }[] };
}) => {
  const [showSubMenu, setShowSubMenu] = React.useState(false);

  const ref = React.useRef(null);

  const handleServiceClick = () => {
    setShowSubMenu((show) => !show);
  };

  useEffect(() => {
    // If current url is one of the sub menu links, show the sub menu
    const currentPath = window.location.pathname;
    const isSubLink = config.links.some((link) =>
      currentPath.startsWith(link.to)
    );
    setShowSubMenu(isSubLink);
  }, []);

  return (
    <div ref={ref} className="clg:w-auto">
      <MenuItem
        onClick={handleServiceClick}
        show={showSubMenu}
        name={config.name}
      />
      <SubMenu show={showSubMenu} links={config.links} />
    </div>
  );
};

import React from "react";
import Testimonials from "~/views/ChardhamHome/Testimonials";

export const Reviews: React.FunctionComponent = () => (
  <div className="" style={{ borderTop: "1px solid #e5e5e5" }}>
    <div className={`py-5 relative`}>
      <div>
        <Testimonials />
      </div>
    </div>
  </div>
);

import React from "react";

import { Portal } from "~/components/Portal";

type Props = {
  children: React.ReactNode;
  onClose: () => void;
};

export const Modal: React.FunctionComponent<Props> = ({
  children,
  onClose,
}) => (
  <Portal>
    <div
      className="fixed top-0 left-0 h-screen w-full overflow-auto z-[100]"
      onClick={onClose}
    >
      <div
        aria-hidden="true"
        className="fixed top-0 left-0 h-screen w-full bg-gray-500 z-[1010] opacity-[0.7]"
      />

      <div
        className="absolute top-0 left-0 bottom-0 opacity-100 z-[1011] min-w-full"
        onClick={(e) => e.stopPropagation()}
      >
        {children}
      </div>
    </div>
  </Portal>
);

import React from "react";
import { Helmet } from "react-helmet";
import * as classes from "./styles.module.css";

import { Footer } from "./Footer";
import { Header } from "./Header";
import { defaultLinks } from "~/constants/links";

type Props = {
  overflowHidden?: boolean;
  pageWidth?: boolean;
  children?: React.ReactNode;
  title?: string;
  header?: React.ReactNode;
  links?: {
    to?: string;
    text: string;
    links?: { to: string; text: string }[];
    type: "link" | "menu";
  }[];
  showBanner?: boolean;
  meta?: string;
};

export const Layout: React.FunctionComponent<Props> = ({
  overflowHidden,
  children,
  title = "Thumby Aviation",
  links = defaultLinks,
  showBanner = false,
  meta = "Fly for business and Leisure in style on our HeliTaxii’s and Chartered Flights!",
}) => {
  let wrapperClass = "min-h-screen flex flex-col";

  if (overflowHidden) {
    wrapperClass += " overflow-hidden";
  }

  return (
    <>
      <Helmet
        htmlAttributes={{
          lang: "en",
        }}
      >
        <meta charSet="utf-8" />
        <title>{title}</title>
        <meta name="description" content={meta} />
      </Helmet>

      <div
        className={`${wrapperClass} ${classes.pageWrapper}`}
        style={{ width: "100%" }}
      >
        <Header links={links} showBanner={showBanner} />
        <main className="flex-1">{children}</main>
        <Footer />
      </div>
    </>
  );
};

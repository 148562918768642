import React, { useEffect } from "react";
import { Link } from "~/components/Layout/Link";
import { LinkMenu } from "./LinkMenu";
import { BookingRequest } from "~/views";
import { useQueryParam } from "use-query-params";
import { navigate } from "gatsby";

type Props = {
  toggleMobileMenuVisibility: () => void;
  links: {
    to?: string;
    text: string;
    special?: boolean;
    links?: { to: string; text: string }[];
    type: "link" | "menu";
  }[];
};

export const Navbar: React.FunctionComponent<Props> = ({
  links,
  toggleMobileMenuVisibility,
}) => {
  const [showBookingRequestForm, setShowBookingRequestForm] =
    React.useState(false);
  const [showContactUs] = useQueryParam("contactUs");

  const handleConnectUs = () => {
    toggleMobileMenuVisibility();
    setShowBookingRequestForm(true);
  };

  const resetQueryParam = () => {
    navigate(location.pathname + location.hash);
  };

  useEffect(() => {
    setShowBookingRequestForm(!!showContactUs);
  }, [showContactUs]);

  return (
    <>
      {links.map(({ to = "", text, type, links = [], special = false }) => {
        if (type === "link") {
          return (
            <div
              style={{
                background: special ? "#3c82f6" : "transparent",
              }}
            >
              <Link
                to={to}
                text={text}
                special={special}
                className="p-2 block"
              />
            </div>
          );
        }

        if (type === "menu") {
          return (
            <div style={{}}>
              <LinkMenu
                config={{
                  name: text,
                  links: links,
                }}
              />
            </div>
          );
        }
      })}
      <div style={{ marginBottom: 10, textAlign: "center", marginTop: 10 }}>
        <button
          className="text-secondary text-sm py-2 px-3 border border-secondary rounded 
          hover:bg-primary-dark hover:text-white self-start"
          onClick={handleConnectUs}
        >
          Connect with us
        </button>
      </div>
      {showBookingRequestForm && (
        <BookingRequest
          enquiryType="General"
          onClose={() => {
            setShowBookingRequestForm(false);
            resetQueryParam();
          }}
        />
      )}
    </>
  );
};

import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import { Heading, Slider } from "~/components";

export const PhotoGallery: React.FunctionComponent = () => (
  <div id="photo-gallery" className={`mt-16 mb-4`}>
    <Heading type="h2" className="text-center">
      Photo Gallery
    </Heading>

    <div className="mt-2">
      <Slider
        settings={{
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
          speed: 500,
          responsive: [
            {
              breakpoint: 1380,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                infinite: true,
                speed: 500,
              },
            },
            {
              breakpoint: 768,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
                speed: 500,
              },
            },
          ],
        }}
      >
        <div className="mr-5">
          <StaticImage
            className="rounded-xl"
            src="../../images/photo-gallery-1.png"
            alt=""
            placeholder="blurred"
            height={250}
            width={350}
          />
        </div>
        <div className="mr-5">
          <StaticImage
            className="rounded-xl"
            src="../../images/photo-gallery-2.png"
            alt=""
            placeholder="blurred"
            height={250}
            width={350}
          />
        </div>
        <div className="mr-5">
          <StaticImage
            className="rounded-xl"
            src="../../images/photo-gallery-3.png"
            alt=""
            placeholder="blurred"
            height={250}
            width={350}
          />
        </div>
        <div className="mr-5">
          <StaticImage
            className="rounded-xl"
            src="../../images/photo-gallery-4.png"
            alt=""
            placeholder="blurred"
            height={250}
            width={350}
          />
        </div>
        <div className="mr-5">
          <StaticImage
            className="rounded-xl"
            src="../../images/bg/3-carousal-2.png"
            alt=""
            placeholder="blurred"
            height={250}
            width={350}
          />
        </div>
      </Slider>
    </div>
  </div>
);

import React from "react";
import { Chip } from ".";

type Props = {
  items: string[];
};

export const Chips: React.FunctionComponent<Props> = ({ items }) => (
  <div className="flex flex-wrap text-primary-dark text-xs">
    {items.map((item) => (
      <Chip key={item} className="self-start" text={item} />
    ))}
  </div>
);

import React from "react";
import { StaticImage } from "gatsby-plugin-image";

import { Heading } from "~/components";
import { HeliVacations } from "./HeliVacations";
import { HospitalityPartners } from "./HospitalityPartners";

export const VacationsAndTourisms: React.FunctionComponent = () => (
  <div className="mb-10 clg:mb-20">
    <Heading className="block clg:hidden">
      Heli-Vacations & <br /> Heli-Tourism
    </Heading>

    <div className="flex flex-col-reverse clg:flex-row">
      <div className="clg:w-[55%]">
        <Heading className="hidden clg:block">
          Heli-Vacations & <br /> Heli-Tourism
        </Heading>

        <HeliVacations />
        <HospitalityPartners />
      </div>

      <div className="mt-8 mb-8 ml-5 clg:ml-24">
        <StaticImage
          placeholder="blurred"
          src="../../images/heli-vacation-image@2x.png"
          alt="evolve back logo"
          height={360}
        />
      </div>
    </div>
  </div>
);

import React from "react";
import type { CustomArrowProps, Settings } from "react-slick";
import BaseSlider from "react-slick";

import PreviousArrowSvg from "~/images/Icon feather-arrow-left.svg";
import NextArrowSvg from "~/images/Icon feather-arrow-right.svg";

export const ArrowNext: React.FunctionComponent<CustomArrowProps> = ({
  onClick,
  style = {},
}) => (
  <div className="absolute -bottom-10 right-[50px]" style={style}>
    <img
      className="cursor-pointer"
      src={NextArrowSvg}
      alt="previous"
      onClick={onClick}
    />
  </div>
);

export const ArrowPrev: React.FunctionComponent<CustomArrowProps> = ({
  onClick,
  style = {},
}) => (
  <div className="absolute -bottom-10 right-[100px]" style={style}>
    <img
      className="cursor-pointer"
      src={PreviousArrowSvg}
      alt="next"
      onClick={onClick}
    />
  </div>
);

const settings: Settings = {
  swipeToSlide: true,
  infinite: true,
  adaptiveHeight: true,
  prevArrow: <ArrowPrev />,
  nextArrow: <ArrowNext className="absolute -bottom-10 right-[50px]" />,
  slidesToScroll: 3,
  slidesToShow: 4,
};

type Props = {
  children: React.ReactNode;
  settings?: Settings;
};

export const Slider: React.FunctionComponent<Props> = ({
  children,
  settings: settingsProp,
}) => {
  const updatedSettings = { ...settings, ...settingsProp };

  return <BaseSlider {...updatedSettings}>{children}</BaseSlider>;
};

import React, { useEffect } from "react";
import { Modal } from "../Modal";
import { Close } from "../Icons";
import { StaticImage } from "gatsby-plugin-image";
import { Heading, Paragraph } from "../Typography";
import { PrimaryButton } from "../Button";
import { SecondaryButton } from "../Button/PrimaryButton";
import { useQueryParam } from "use-query-params";

export const AnnouncementModal = ({
  onBook,
  onLearnMore,
}: {
  onBook?: () => void;
  onLearnMore?: () => void;
}) => {
  const [open, setOpen] = React.useState(false);
  const [showContactUs] = useQueryParam("contactUs");

  useEffect(() => {
    if (showContactUs) return;

    setTimeout(() => {
      setOpen(true);
    }, 2000);
  }, [showContactUs]);

  const onClose = () => {
    setOpen(false);
  };

  return open ? (
    <Modal onClose={onClose}>
      <div className="flex flex-col min-h-screen justify-center items-center pb-5">
        <div className="flex flex-col clg:flex-row rounded-lg mt-10 relative">
          <button
            className="block w-8 my-3 self-end float-right absolute top-1 right-7 text-white z-10"
            onClick={onClose}
          >
            <Close className="h-7 w-7" />
          </button>{" "}
          <div className="w-full md:w-[450px] bg-white rounded-lg">
            <div className="flex justify-center align-center">
              <StaticImage
                placeholder="blurred"
                src="../../images/VT-GRD-Bel-412.jpg"
                alt="Request Sent"
                objectFit="cover"
                className="w-full"
              />
            </div>
            <div className="flex justify-center align-center flex-col px-8">
              <Heading className="text-center" type="h2">
                Dear All!
              </Heading>
              <Paragraph className="text-primary text-center">
                Thumby Aviation is pleased to welcome VT-GRD (Bell 412) as the
                newest member of our fleet!
              </Paragraph>
            </div>

            <div className="text-center pb-10">
              {onLearnMore && (
                <SecondaryButton
                  className="mr-2"
                  onClick={() => {
                    onLearnMore();
                    onClose();
                  }}
                >
                  Learn More
                </SecondaryButton>
              )}
              {onBook && (
                <PrimaryButton onClick={onBook}>Book Now</PrimaryButton>
              )}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  ) : null;
};

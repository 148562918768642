import React from "react";
import {
  Heading,
  LocationMarkerIcon,
  PrimaryButton,
  Slider,
} from "~/components";

import { OperatingLocation, operatingLocations } from "./locations";
import { BookingRequest } from "~/views/BookingRequest";

type CardProps = OperatingLocation;

const Card: React.FunctionComponent<CardProps> = ({ Image, place, state }) => (
  <div className="relative">
    <div className="mr-5">
      <Image />
    </div>
    <div className="absolute top-0 h-full w-full p-5 pb-7 flex flex-col justify-end">
      <Heading type="h3" className="text-[#FFF] mb-0">
        {place}
      </Heading>

      <p className="font-montserrat text-xs clg:text-sm text-gray-300 flex items-center">
        <LocationMarkerIcon className="w-5 h-5 mr-1 text-white" />
        <span>{state}</span>
      </p>
    </div>
  </div>
);

export const OperatingLocations: React.FunctionComponent = () => {
  const [showBookingRequestForm, setShowBookingRequestForm] =
    React.useState(false);

  return (
    <div id="operating-locations" className={`mt-16 mb-20`}>
      <Heading className="mb-10 text-center" type="h2">
        Popular Destinations
      </Heading>

      <Slider
        settings={{
          slidesToShow: 4,
          slidesToScroll: 4,
          infinite: true,
          variableWidth: true,
          responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                infinite: true,
              },
            },
            {
              breakpoint: 768,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                infinite: true,
              },
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
              },
            },
          ],
        }}
      >
        {operatingLocations.map(({ place, state, Image }) => (
          <Card key={place} place={place} state={state} Image={Image} />
        ))}
      </Slider>

      <div className="mt-20 text-center">
        <PrimaryButton
          className="mr-10 py-5 px-16 rounded-lg"
          onClick={() => {
            setShowBookingRequestForm(true);
          }}
        >
          Book Now
        </PrimaryButton>
      </div>
      {showBookingRequestForm && (
        <BookingRequest
          enquiryType="General"
          onClose={() => {
            setShowBookingRequestForm(false);
          }}
        />
      )}
    </div>
  );
};

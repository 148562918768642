import React from "react";
import { navigate } from "gatsby";

import { Heading, Slider } from "~/components";

import { Service, servicesOfferred } from "./servicesOfferred";

type CardProps = Service;

const Card: React.FunctionComponent<CardProps> = ({
  title,
  text,
  link,
  Image,
}) => (
  <div className="relative">
    <div>
      <Image />
    </div>
    <div
      className="absolute top-0 h-full w-full p-5 cursor-pointer"
      onClick={() => navigate(link)}
    >
      <Heading className="text-[#FFF]" type="h2">
        {title}
      </Heading>

      <p className="font-montserrat text-md mt-10 font-medium text-white">
        {text}
      </p>
    </div>
  </div>
);

export const Services: React.FunctionComponent = () => (
  <div id="services" style={{ borderTop: "1px solid #e5e5e5" }}>
    <Heading className="text-center mb-10" type="h2">
      Our Presence
    </Heading>
    <Slider
      settings={{
        slidesToShow: 2,
        slidesToScroll: 2,
        infinite: true,
        variableWidth: true,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              infinite: true,
            },
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              infinite: true,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              infinite: true,
            },
          },
        ],
      }}
    >
      {servicesOfferred.map(({ title, text, Image, link }) => (
        <Card key={title} title={title} text={text} Image={Image} link={link} />
      ))}
    </Slider>
  </div>
);

import React from "react";
import Facebook from "~/images/social-facebook.svg";
import Insta from "~/images/social-insta.svg";

export const SocialLinks = () => (
  <div className="inline-flex space-x-2">
    <a className="insta" href="https://instagram.com/thumbyaviation">
      <img src={Insta} alt="insta" height={28} width={28} />
    </a>
    <a href="https://www.facebook.com/HeliJoyRides/">
      <img src={Facebook} alt="facebook" height={28} width={28} />
    </a>
  </div>
);

import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import { Heading, Slider } from "~/components";

export const PhotoGallery: React.FunctionComponent = () => (
  <div id="photo-gallery" className={`mt-24 mb-20`}>
    <Heading type="h2" className="text-center">
      Photo Gallery
    </Heading>

    <div className="mt-14">
      <Slider
        settings={{
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
          speed: 500,
          responsive: [
            {
              breakpoint: 992,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                infinite: true,
                speed: 500,
              },
            },
            {
              breakpoint: 768,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                infinite: true,
                speed: 500,
              },
            },
          ],
        }}
      >
        <div className="mr-2">
          <StaticImage
            placeholder="blurred"
            className="rounded-xl mr-2"
            src="../../../images/bg/1-chardham-carousel.png"
            alt="pilgrimage-banner"
            height={250}
            width={350}
          />
        </div>
        <div className="mr-2">
          <StaticImage
            placeholder="blurred"
            className="rounded-xl mr-2"
            src="../../../images/bg/3-carousal-2.png"
            alt="pilgrimage-banner"
            height={250}
            width={350}
          />
        </div>
        <div className="mr-2">
          <StaticImage
            placeholder="blurred"
            className="rounded-xl mr-2"
            src="../../../images/blog-1-cover-image.png"
            alt="pilgrimage-banner"
            height={250}
            width={350}
          />
        </div>
        <div className="mr-2">
          <StaticImage
            placeholder="blurred"
            className="rounded-xl mr-2"
            src="../../../images/bg/4-carousel.jpeg"
            alt="pilgrimage-banner"
            height={250}
            width={350}
          />
        </div>
        <div className="mr-2">
          <StaticImage
            placeholder="blurred"
            className="rounded-xl mr-2"
            src="../../../images/bg/5-carousel.jpeg"
            alt="pilgrimage-banner"
            height={250}
            width={350}
          />
        </div>
        <div className="mr-2">
          <StaticImage
            placeholder="blurred"
            className="rounded-xl mr-2"
            src="../../../images/bg/6-carousel.jpeg"
            alt="pilgrimage-banner"
            height={250}
            width={350}
          />
        </div>
        <div className="mr-2">
          <StaticImage
            placeholder="blurred"
            className="rounded-xl mr-2"
            src="../../../images/bg/7-carousel.jpeg"
            alt="pilgrimage-banner"
            height={250}
            width={350}
          />
        </div>
        <div className="mr-2">
          <StaticImage
            placeholder="blurred"
            className="rounded-xl mr-2"
            src="../../../images/bg/8-carousel.jpeg"
            alt="pilgrimage-banner"
            height={250}
            width={350}
          />
        </div>
        <div className="mr-2">
          <StaticImage
            className="rounded-xl mr-2"
            src="../../../images/photo-gallery-1.png"
            alt=""
            placeholder="blurred"
            height={250}
            width={350}
          />
        </div>
        <div className="mr-2">
          <StaticImage
            className="rounded-xl mr-2"
            src="../../../images/photo-gallery-2.png"
            alt=""
            placeholder="blurred"
            height={250}
            width={350}
          />
        </div>
        <div className="mr-2">
          <StaticImage
            className="rounded-xl mr-2"
            src="../../../images/photo-gallery-3.png"
            alt=""
            placeholder="blurred"
            height={250}
            width={350}
          />
        </div>
        <div className="mr-2">
          <StaticImage
            className="rounded-xl mr-2"
            src="../../../images/photo-gallery-4.png"
            alt=""
            placeholder="blurred"
            height={250}
            width={350}
          />
        </div>
        <div className="mr-2">
          <StaticImage
            className="rounded-xl mr-2"
            src="../../../images/bg/3-carousal-2.png"
            alt=""
            placeholder="blurred"
            height={250}
            width={350}
          />
        </div>
        <div className="mr-2">
          <StaticImage
            className="rounded-xl mr-2"
            src="../../../images/client-1.png"
            alt=""
            placeholder="blurred"
            height={250}
            width={350}
          />
        </div>
        <div className="mr-2">
          <StaticImage
            className="rounded-xl mr-2"
            src="../../../images/client-2.png"
            alt=""
            placeholder="blurred"
            height={250}
            width={350}
          />
        </div>
        <div className="mr-2">
          <StaticImage
            className="rounded-xl mr-2"
            src="../../../images/client-3.png"
            alt=""
            placeholder="blurred"
            height={250}
            width={350}
          />
        </div>
        <div className="mr-2">
          <StaticImage
            className="rounded-xl mr-2"
            src="../../../images/client-4.jpeg"
            alt=""
            placeholder="blurred"
            height={250}
            width={350}
          />
        </div>
        <div className="mr-2">
          <StaticImage
            className="rounded-xl mr-2"
            src="../../../images/bell-412.jpeg"
            alt="charter-bell-407-03"
            height={250}
            width={350}
          />
        </div>
        <div className="mr-2">
          <StaticImage
            className="rounded-xl mr-2"
            src="../../../images/airbus-h125.jpeg"
            alt="charter-bell-407-03"
            height={250}
            width={350}
          />
        </div>
        <div className="mr-2">
          <StaticImage
            className="rounded-xl mr-2"
            src="../../../images/Charters 04@2x.png"
            alt="charter-bell-407-03"
            height={250}
            width={350}
          />
        </div>
        <div className="mr-2">
          <StaticImage
            className="rounded-xl mr-2"
            src="../../../images/heliwedding-01@2x.png"
            alt=""
            placeholder="blurred"
            height={250}
            width={350}
          />
        </div>
        <div className="mr-2">
          <StaticImage
            className="rounded-xl mr-2"
            src="../../../images/heliwedding-02@2x.png"
            alt=""
            placeholder="blurred"
            height={250}
            width={350}
          />
        </div>
      </Slider>
    </div>
  </div>
);

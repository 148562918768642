import React from "react";

import { AboutUsParagraph } from "./AboutUsParagraph";

export const AboutUs: React.FunctionComponent = () => (
  <div id="about-us" className="mt-5 py-5">
    {/* Row 1 Image + text */}
    <AboutUsParagraph />
  </div>
);

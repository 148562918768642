import React, { useCallback, useEffect, useState } from "react";
import { StaticImage } from "gatsby-plugin-image";

import { Close } from "~/components/Icons";
import { Heading, PrimaryButton } from "~/components";
import { TBookingRequest } from "~/api";
import "react-phone-number-input/style.css";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import { getScheduleAvailability, getSchedules } from "~/api/charterSchedule";

type Props = {
  onSubmit: (scheduleId: string, data: TBookingRequest) => void;
  onClose: () => void;
  setLoading: (loading: boolean) => void;
};

const getDateInYYYYMMDD = (dateInMMDDYYYY: string) => {
  const [month, date, year] = dateInMMDDYYYY.split("/");

  return `${year}-${month}-${date}`;
};

const getDateInMMDDYYYY = (dateInYYYYMMDD: string) => {
  const [year, month, date] = dateInYYYYMMDD.split("-");

  return `${month}/${date}/${year}`;
};

const getFarthestDate = (dates: string[]) => {
  const dateObjects = dates.map((date) =>
    new Date(getDateInYYYYMMDD(date)).getTime()
  );

  const farthestDate = new Date(Math.max.apply(null, dateObjects));

  const year = farthestDate.getFullYear();
  const month = farthestDate.getMonth() + 1;
  const date = farthestDate.getDate();

  return `${year}-${month > 9 ? month : "0" + month}-${
    date > 9 ? date : "0" + date
  }`;
};

const getMinDate = (dates: string[]) => {
  const dateObjects = dates.map((date) =>
    new Date(getDateInYYYYMMDD(date)).getTime()
  );

  const minDate = new Date(Math.min.apply(null, dateObjects));
  const minDateInYYYYMMDD = `${
    minDate.getMonth() + 1
  }/${minDate.getDate()}/${minDate.getFullYear()}`;
  const today = new Date();

  const todayInYYYYMMDD = `${
    today.getMonth() + 1
  }/${today.getDate()}/${today.getFullYear()}`;

  const todayOrFirstAvailableDate = getFarthestDate([
    minDateInYYYYMMDD,
    todayInYYYYMMDD,
  ]);

  return todayOrFirstAvailableDate;
};

export const BookingRequestForm: React.FunctionComponent<Props> = ({
  onSubmit,
  onClose,
  setLoading,
}) => {
  const [phoneNumber, setPhoneNumber] = useState<any>("");
  const bookingRequestFormRef = React.useRef(null);
  const [availability, setAvailability] = useState<any>(null);
  const [schedules, setSchedules] = useState<any>([]);
  const [availableSeats, setAvailableSeats] = useState<any>(0);

  const handleClose = () => {
    onClose();
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();

    if (!isValidPhoneNumber(phoneNumber)) {
      return;
    }

    const formData: Record<string, string> = {};
    const data = new FormData(bookingRequestFormRef.current!) as any;

    for (const [name, value] of data) {
      formData[name] = value;
    }

    const [year, month, date] = formData.travelDate.split("-");
    const travelDate = `${month}/${date}/${year}`;

    const availableSeatsOnDate = availability[travelDate] || 0;
    const seats = parseInt(formData.seats, 10);

    if (availableSeatsOnDate < seats) {
      return;
    }

    onSubmit(formData.schedule, {
      ...formData,
      mobileNumber: phoneNumber,
      travelDate: `${month}/${date}/${year}`,
      seats,
    } as TBookingRequest);
  };

  const fetchSchedules = useCallback(async () => {
    try {
      setLoading(true);
      const schedules = await getSchedules();
      setSchedules(schedules);
    } catch (error: any) {
      setSchedules([]);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchSchedules();
  }, [fetchSchedules]);

  const onChangeSchedule = async (scheduleId: string) => {
    try {
      setLoading(true);
      const availability = await getScheduleAvailability(scheduleId);
      setAvailability(availability);

      if (bookingRequestFormRef.current) {
        const travelDateInput = (
          bookingRequestFormRef.current as HTMLFormElement
        ).querySelector("#travelDate") as HTMLInputElement;

        const seatsSelect = (
          bookingRequestFormRef.current as HTMLFormElement
        ).querySelector("#seats") as HTMLSelectElement;

        seatsSelect.value = "";
        travelDateInput.value = "";
        setAvailableSeats(0);
      }
    } catch (error: any) {
      setAvailability(null);
    } finally {
      setLoading(false);
    }
  };

  const onChangeDate = (date: string) => {
    const dateInMMDDYY = getDateInMMDDYYYY(date);
    const availableSeats = availability[dateInMMDDYY];
    if (bookingRequestFormRef.current) {
      const seatsSelect = (
        bookingRequestFormRef.current as HTMLFormElement
      ).querySelector("#seats") as HTMLSelectElement;

      seatsSelect.value = "";
    }
    setAvailableSeats(availableSeats);
  };

  const maxDate = availability
    ? getFarthestDate(Object.keys(availability))
    : "";

  const minDate = availability ? getMinDate(Object.keys(availability)) : "";

  return (
    <>
      <button
        className="block clg:hidden w-8 my-3 self-end float-right absolute top-1 right-7 text-white z-10"
        onClick={handleClose}
      >
        <Close className="h-7 w-7" />
      </button>

      <StaticImage
        className="h-[90px] rounded-t-lg clg:rounded-none clg:h-auto"
        src="../../images/form image@2x.png"
        alt=""
        placeholder="blurred"
        height={500}
      />

      <div className="w-full clg:h-[550px] bg-white rounded-b-lg clg:rounded-bl-none clg:rounded-r-lg">
        <button
          className="hidden clg:block w-8 my-3 self-end absolute top-1 right-8"
          onClick={handleClose}
        >
          <Close className="h-7 w-7" />
        </button>

        <Heading type="h4" className="text-center">
          Contact Us
        </Heading>
        <div className="flex flex-col space-y-2 mx-10 text-sm">
          {/* WhatsApp Button */}
          <button
            className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded flex align-center justify-center"
            onClick={() => {
              /* handle WhatsApp contact logic here */
              window.open(
                "https://wa.me/918951517811?text=I'm%20interested%20in%20your%20tour%20packages",
                "_blank"
              );
            }}
          >
            <svg
              className="mr-2"
              fill="#ffffff"
              height="20px"
              width="20px"
              version="1.1"
              id="Layer_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              viewBox="0 0 308 308"
              xmlSpace="preserve"
            >
              <g id="XMLID_468_">
                <path
                  id="XMLID_469_"
                  d="M227.904,176.981c-0.6-0.288-23.054-11.345-27.044-12.781c-1.629-0.585-3.374-1.156-5.23-1.156
		c-3.032,0-5.579,1.511-7.563,4.479c-2.243,3.334-9.033,11.271-11.131,13.642c-0.274,0.313-0.648,0.687-0.872,0.687
		c-0.201,0-3.676-1.431-4.728-1.888c-24.087-10.463-42.37-35.624-44.877-39.867c-0.358-0.61-0.373-0.887-0.376-0.887
		c0.088-0.323,0.898-1.135,1.316-1.554c1.223-1.21,2.548-2.805,3.83-4.348c0.607-0.731,1.215-1.463,1.812-2.153
		c1.86-2.164,2.688-3.844,3.648-5.79l0.503-1.011c2.344-4.657,0.342-8.587-0.305-9.856c-0.531-1.062-10.012-23.944-11.02-26.348
		c-2.424-5.801-5.627-8.502-10.078-8.502c-0.413,0,0,0-1.732,0.073c-2.109,0.089-13.594,1.601-18.672,4.802
		c-5.385,3.395-14.495,14.217-14.495,33.249c0,17.129,10.87,33.302,15.537,39.453c0.116,0.155,0.329,0.47,0.638,0.922
		c17.873,26.102,40.154,45.446,62.741,54.469c21.745,8.686,32.042,9.69,37.896,9.69c0.001,0,0.001,0,0.001,0
		c2.46,0,4.429-0.193,6.166-0.364l1.102-0.105c7.512-0.666,24.02-9.22,27.775-19.655c2.958-8.219,3.738-17.199,1.77-20.458
		C233.168,179.508,230.845,178.393,227.904,176.981z"
                />
                <path
                  id="XMLID_470_"
                  d="M156.734,0C73.318,0,5.454,67.354,5.454,150.143c0,26.777,7.166,52.988,20.741,75.928L0.212,302.716
		c-0.484,1.429-0.124,3.009,0.933,4.085C1.908,307.58,2.943,308,4,308c0.405,0,0.813-0.061,1.211-0.188l79.92-25.396
		c21.87,11.685,46.588,17.853,71.604,17.853C240.143,300.27,308,232.923,308,150.143C308,67.354,240.143,0,156.734,0z
		 M156.734,268.994c-23.539,0-46.338-6.797-65.936-19.657c-0.659-0.433-1.424-0.655-2.194-0.655c-0.407,0-0.815,0.062-1.212,0.188
		l-40.035,12.726l12.924-38.129c0.418-1.234,0.209-2.595-0.561-3.647c-14.924-20.392-22.813-44.485-22.813-69.677
		c0-65.543,53.754-118.867,119.826-118.867c66.064,0,119.812,53.324,119.812,118.867
		C276.546,215.678,222.799,268.994,156.734,268.994z"
                />
              </g>
            </svg>{" "}
            Contact on WhatsApp
          </button>

          {/* Call Button */}

          <a href="tel:+918951517811" className="block">
            <button className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded w-[100%]">
              Call us a +91 89515 17811
            </button>
          </a>
        </div>

        <hr className="mb-2 mt-3" />
        <Heading type="h4" className="text-center my-0">
          Book now / Request Callback
        </Heading>

        <form
          ref={bookingRequestFormRef}
          id="bookingRequestForm"
          className="mx-10 mt-2 text-sm"
          onSubmit={handleSubmit}
        >
          {/* Full name */}
          <div className="flex items-center">
            {/* Half width */}
            <div className="flex flex-col w-[50%] mr-2">
              <label className="text-primary font-poppins mb-1" htmlFor="name">
                Full Name
              </label>
              <input
                className="p-[6px] border border-gray-300 rounded-lg"
                type="text"
                name="name"
                id="name"
                required
                placeholder="Enter full name"
              />
            </div>

            {/* Email */}
            <div className="flex flex-col w-[50%]">
              <label className="text-primary font-poppins mb-1" htmlFor="email">
                Email
              </label>
              <input
                className="p-[6px] border border-gray-300 rounded-lg"
                type="email"
                name="email"
                id="email"
                placeholder="Enter email address"
                required
              />
            </div>
          </div>

          {/* Schedules dropdown */}
          <div className="mt-3 flex flex-col">
            <label
              className="text-primary font-poppins mb-1"
              htmlFor="schedule"
            >
              Schedule
            </label>
            <select
              className="p-[6px] border border-gray-300 rounded-lg"
              name="schedule"
              id="schedule"
              required
              onChange={(e) => {
                onChangeSchedule(e.target.value);
              }}
            >
              <option value="">Select schedule</option>
              {schedules.map((schedule: any) => (
                <option key={schedule._id} value={schedule._id}>
                  {schedule.name}
                </option>
              ))}
            </select>
          </div>

          <div className="flex items-center">
            <div className="mt-3 flex flex-col w-[50%] mr-2">
              <label
                className="text-primary font-poppins mb-1"
                htmlFor="travelDate"
              >
                Travel date
              </label>
              <input
                className="p-[6px] border border-gray-300 rounded-lg"
                type="date"
                name="travelDate"
                id="travelDate"
                placeholder="MM/DD/YYYY"
                required
                min={minDate}
                disabled={!availability}
                max={maxDate}
                onChange={(e) => {
                  onChangeDate(e.target.value);
                }}
              />
            </div>

            <div className="mt-3 flex flex-col w-[50%]">
              <label className="text-primary font-poppins mb-1" htmlFor="seats">
                Seats
              </label>
              <select
                className="p-[6px] border border-gray-300 rounded-lg"
                name="seats"
                id="seats"
                required
                disabled={availableSeats === 0}
              >
                <option value="">Select Seats</option>
                <option value="2" disabled={availableSeats < 2}>
                  2
                </option>
                <option value="4" disabled={availableSeats < 4}>
                  4
                </option>
                <option value="6" disabled={availableSeats < 6}>
                  6
                </option>
              </select>
            </div>
          </div>

          {/* Email */}
          <div className="mt-3 flex flex-col">
            <label
              className="text-primary font-poppins mb-1"
              htmlFor="contactNumber"
            >
              Mobile Number
            </label>
            <PhoneInput
              required
              placeholder="Enter phone number"
              onChange={setPhoneNumber}
              value={phoneNumber}
              defaultCountry="IN"
              className={`p-[6px] border rounded-lg ${
                !phoneNumber || isValidPhoneNumber(phoneNumber)
                  ? "border-gray-300"
                  : "border-red-800"
              }`}
            />
          </div>
          <PrimaryButton className="mt-5 mb-7 clg:mb-0" type="submit">
            Submit
          </PrimaryButton>
        </form>
      </div>
    </>
  );
};

import React from "react";
import { StaticImage } from "gatsby-plugin-image";

import { Heading, Paragraph } from "~/components";

type TextProps = {
  children: React.ReactNode;
};

const TextTitle: React.FunctionComponent<TextProps> = ({ children }) => (
  <p
    className="font-poppins text-primary leading-5 mb-2 pl-2 border-l-4 
      text-md font-semibold border-[#0ad5cc]"
  >
    {children}
  </p>
);

export const GovernmentWorks: React.FunctionComponent = () => (
  <div className="mb-10 clg:mb-20">
    <Heading className="block clg:hidden">Government Works</Heading>

    <div className="flex flex-col-reverse clg:flex-row">
      <div className="clg:w-[60%]">
        <Heading className="hidden clg:block">Government Works</Heading>
        <section>
          <Paragraph>
            The company has been operating for State Government / Central
            Government agencies for over a decade.
          </Paragraph>
          <Paragraph className="mt-7">
            Currently, 2 Bell 412 helicopter(s) are being operated for the State
            Government of Nagaland connecting all districts of the State for
            Passenger Transportation, Medivac and also for VIP flights.
          </Paragraph>
        </section>

        <section className="mt-10">
          <Heading type="h3">Ongoing Projects</Heading>

          <TextTitle>
            Government of Nagaland / Ministry of Home Affairs –
          </TextTitle>
          <Paragraph className="pl-3 mb-10">
            2 Bell 412 helicopter(s) for passenger transportation and VIP
            flights
          </Paragraph>

          <TextTitle>Border Security Force</TextTitle>
          <Paragraph className="pl-3 mb-10">
            Operation and Maintenance contract of 2 ALH Dhruv Helicopter(s)
          </Paragraph>

          <TextTitle>Other Government Clients from the past</TextTitle>
          <Paragraph className="pl-3 mb-10">
            Government of Telangana, Telangana State Aviation Corporation,
            Government of Andhra Pradesh, Government of Karnataka, Government of
            Chhatthisgarh, Government of Kerala, Tourism Department
            Karnataka/Kerala/TS and AP
          </Paragraph>

          <div className="flex">{/* TODO: Assets missing */}</div>
        </section>
      </div>

      <div className="mt-8 mb-8 ml-5 clg:ml-24">
        <StaticImage
          placeholder="blurred"
          src="../../images/govwork-image@2x.png"
          alt="evolve back logo"
          height={360}
        />
      </div>
    </div>
  </div>
);

import axios from "axios";

export type TBookingRequest = {
  name: string;
  mobileNumber: string;
  seats: number;
  travelDate: string;
  email: string;
  campaign?: {
    utmSource: string;
    utmMedium: string;
    utmCampaign: string;
  };
};

export const getSchedules = async () => {
  const data = await axios.get("/charterSchedules");
  return data.data.schedules;
};

export const getScheduleAvailability = async (scheduleId: string) => {
  const { data } = await axios.get(
    `/charterSchedules/${scheduleId}/availability`
  );

  return data.availableDatesMap;
};

export const createBooking = async (
  scheduleId: string,
  booking: TBookingRequest
) => {
  const { data } = await axios.post(
    `/charterSchedules/${scheduleId}/book`,
    booking
  );

  return data.bookingId;
};

export const getBooking = async (token: string) => {
  const { data } = await axios.get(`/charterBooking/token/${token}`);
  return data.booking;
};

export const completeBooking = async (
  bookingId: string,
  token: string,
  passengers: {
    name: string;
    age: number;
    weight: number;
    idCardNumber: string;
    gender: string;
  }[]
) => {
  await axios.post(`/charterBooking/${bookingId}/complete?token=${token}`, {
    passengers,
  });
};

import React from "react";

type Props = {
  children: React.ReactNode;
};

export const Text: React.FunctionComponent<Props> = ({ children }) => (
  <p className="font-montserrat leading-relaxed text-[#29305c] font-medium text-[28px] mt-5 clg:mt-0">
    {children}
  </p>
);

export const prices = {
  charDham5N6D: {
    price: "₹ 2,30,000",
    discountedPrice: "₹ 2,20,000",
  },
  charDham1N2D: { price: "₹ 12,80,000", discountedPrice: "₹ 12,50,000" },
  ekDham: {
    price: "₹ 4,50,000",
    discountedPrice: "₹ 4,20,000",
  },
  doDham: {
    price: "₹ 1,10,000",
    discountedPrice: "₹ 1,05,000",
  },
};

// extracted by mini-css-extract-plugin
export var reviewAuthorName = "styles-module--reviewAuthorName--ZF0GL";
export var reviewContent = "styles-module--reviewContent--yTu+n";
export var reviewAuthorDescription = "styles-module--reviewAuthorDescription--8+US9";
export var mainReviewContent = "styles-module--mainReviewContent--SgrVQ";
export var mainTestimonialWrapper = "styles-module--mainTestimonialWrapper--TGJB2";
export var heroSectionWrapper = "styles-module--heroSectionWrapper--DXNpx";
export var slideWrapper = "styles-module--slideWrapper--NdAMC";
export var scrollableContenWrapper = "styles-module--scrollableContenWrapper--UmGsF";
export var earlyBirdBadge = "styles-module--earlyBirdBadge--NHR75";
export var slide = "styles-module--slide--JPWa0";
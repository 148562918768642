import React from "react";

import { CompanyLinks } from "./CompanyLinks";
import { LegalLinks } from "./LegalLinks";
import { LogoAndMission } from "./LogoAndMission";
import { SocialLinks } from "./SocialLinks";

type Props = {
  className?: string;
};

export const Footer: React.FunctionComponent<Props> = ({ className = "" }) => (
  <div className="border-t border-gray-200 pt-10 pb-24 mt-10">
    <div className={`flex flex-wrap justify-between text-primary ${className}`}>
      <div className="clg:w-[40%] flex flex-col items-start space-y-5 clg:mr-28">
        <LogoAndMission />
        <SocialLinks />
      </div>

      <div className="flex flex-1 space-x-14 clg:space-x-32 mt-10 clg:mt-0">
        <CompanyLinks />
        <LegalLinks />
      </div>
    </div>
  </div>
);

import React from "react";
import { StaticImage } from "gatsby-plugin-image";

import { Heading, Paragraph } from "~/components";

const PartnerLogos = () => (
  <div className="flex items-center space-x-5 my-10">
    <StaticImage
      placeholder="blurred"
      src="../../images/logo 01@2x.png"
      alt="evolve back logo"
      height={60}
    />
    <StaticImage
      placeholder="blurred"
      src="../../images/logo 02@2x.png"
      alt="the serai logo"
      height={60}
    />
    <StaticImage
      placeholder="blurred"
      className="w-[170px] h-[47px]"
      src="../../images/logo 03.png"
      alt="java rain logo"
    />
  </div>
);

export const HospitalityPartners = () => (
  <section className="mt-14">
    <Heading type="h3">Hospitality Partners</Heading>
    <PartnerLogos />

    <Heading className="mb-2 pl-2 border-l-4 border-[#0ad5cc]" type="h3">
      Evolve Back Resorts
    </Heading>
    <Paragraph className="pl-3 mb-8">
      Helicopter Services to Evolve Back Resorts in Kabini, Coorg and Hampi are
      provided by the company. The helipads are either inside the property
      (Coorg and Kabini) or in close proximity (Hampi). For best package costs
      to any of these destinations, please reach out to our customer care
      number.
    </Paragraph>

    <Heading className="mb-2 pl-2 border-l-4 border-[#0ad5cc]" type="h3">
      The Serai Resorts
    </Heading>
    <Paragraph className="pl-3 mb-8">
      Helicopter services are available for the Serai Resorts in Chikmagaluru
      and Kabini.
    </Paragraph>

    <Heading className="mb-2 pl-2 border-l-4 border-[#0ad5cc]" type="h3">
      Java Rain Resorts
    </Heading>
    <Paragraph className="pl-3 mb-8">
      Helicopter services are available to Java Rain Resorts Chikmagaluru
    </Paragraph>
  </section>
);

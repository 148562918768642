import React from "react";
import { Projects } from "./Projects";

import { TitleRow } from "./TitleRow";

export const FutureProjects: React.FunctionComponent = () => (
  <div id="future-projects" className="bg-future-section px-10 clg:px-28 py-16">
    <TitleRow />
    <Projects />
  </div>
);
